import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import RBarButton from './RBarButton'

import {
  modeSelector,
  MODE,
  zoomAtGlobalPoint,
  getContainerCenter
} from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const RZoomInButton = (props) => {
  const classes = useStyles()

  const mode = useSelector(modeSelector)
  const containerCenter = useSelector(getContainerCenter)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(zoomAtGlobalPoint(true, containerCenter))
  }, [dispatch, containerCenter])

  return (
    <RBarButton
      {...props}
      tooltip="Zoom in"
      onClick={handleClick}
      disabled={false}
    >
      <ZoomInIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RZoomInButton
