import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import RBarButton from './RBarButton'

import {
  ROTATION_VELOCITY,
  modeSelector,
  rotateLeft,
  setMode,
  MODE
} from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const RAutomaticRotationButton = (props) => {
  const [timerId, setTimerId] = useState(null)

  const classes = useStyles()

  const mode = useSelector(modeSelector)
  const dispatch = useDispatch()

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const rotateLeftD = useCallback(() => {
    dispatch(rotateLeft(1))
  }, [dispatch])

  const handleClick = (e) => {
    if (mode === MODE.ROT_AUTOMATIC) {
      clearInterval(timerId)
      setTimerId(null)
      setModeD(MODE.BUTTONS)
    } else {
      setTimerId(setInterval(rotateLeftD, ROTATION_VELOCITY))
      setModeD(MODE.ROT_AUTOMATIC)
    }
  }
  return (
    <RBarButton
      {...props}
      tooltip="Automatic rotation"
      onClick={handleClick}
      disabled={
        mode === MODE.PAN || mode === MODE.ROT_MOUSE || mode === MODE.PANNING
      }
      checked={mode === MODE.ROT_AUTOMATIC}
    >
      {mode === MODE.ROT_AUTOMATIC ? (
        <StopIcon fontSize="inherit" />
      ) : (
        <PlayArrowIcon fontSize="inherit" />
      )}
    </RBarButton>
  )
}
export default RAutomaticRotationButton
