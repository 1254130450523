import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { getFloorHouses } from '../../../modules/reducerHouses'
import { getStepCount } from '../../../modules/reducerRotator'
import {
  floorSelector,
  setFloor,
  getCurrentFloorHousesMaskColors,
  getStepMask
} from '../../../modules/reducerMasks'
import { similarColors, rgbaColor, centroid } from '../mathFunctions'

const useStyles = makeStyles((theme) => ({
  canvas: {
    maxHeight: '25%',
    overflow: 'auto',
    margin: 'auto'
  },
  text: {
    backgroundColor: 'white',
    overflow: 'auto',
    width: '100%',
    maxHeight: '75%',
    margin: 'auto'
  }
}))

function RContourGenerator() {
  const canvasRef = useRef(null)
  const floor = useSelector(floorSelector)
  const floorHousesColors = useSelector(getCurrentFloorHousesMaskColors)
  const [currentStep, setCurrentStep] = useState(-1)
  const gStepMask = useSelector(getStepMask)
  const [text, setText] = useState('')
  const [steps, setSteps] = useState([])
  const gFloorHouses = useSelector(getFloorHouses)
  const stepCount = useSelector(getStepCount)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  //Region de cada color. Supone que todos los puntos estan bajon un mismo
  //contorno
  const getRegions = useCallback(
    (maskData) => {
      const regions = []
      for (let i = 0; i < floorHousesColors.length; ++i) {
        regions.push([])
      }
      for (let x = 0; x < maskData.width; ++x) {
        for (let y = 0; y < maskData.height; ++y) {
          const rIdx = (y * maskData.width + x) * 4
          const gIdx = rIdx + 1
          const bIdx = rIdx + 2
          const aIdx = rIdx + 3
          for (let i = 0; i < floorHousesColors.length; ++i) {
            const houseColor = floorHousesColors[i]
            if (
              // maskData.data[aIdx] !== 0 &&
              similarColors(
                rgbaColor(
                  maskData.data[rIdx],
                  maskData.data[gIdx],
                  maskData.data[bIdx],
                  maskData.data[aIdx]
                ),
                houseColor.rgba
              )
            ) {
              regions[i].push([x, y])
              break
            }
          }
        }
      }
      return regions
    },
    [floorHousesColors]
  )

  //Contorno de una region
  const getContour = (region) => {
    if (region.length > 0) {
      const hullPoints = window.hull(region, 10)
      // return hullPoints
      return window.simplify(hullPoints, 0.5, true)
    } else {
      return []
    }
  }

  //Lee la mascara del paso actual
  const readMaskImage = useCallback(
    (maskData) => {
      const regions = getRegions(maskData)
      const contours = []
      for (let i = 0; i < floorHousesColors.length; ++i) {
        const contour = getContour(regions[i])
        const contourObj = {
          houseId: floorHousesColors[i].houseId,
          contour: contour,
          centroid: centroid(contour)
        }
        contours.push(contourObj)
      }
      return contours
    },
    [getRegions, floorHousesColors]
  )

  const handleIniciar = () => {
    dispatch(setFloor('f0'))
    setCurrentStep(0)
  }

  useEffect(() => {
    if (loading || currentStep >= stepCount || currentStep < 0) {
      return
    }
    //Lee datos mascara
    const canvas = canvasRef.current
    if (canvas) {
      var img = new Image()
      img.onload = function () {
        console.log(currentStep, 'loaded')
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0)
        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        setSteps((steps) => [...steps, readMaskImage(imgData)])
        setCurrentStep(currentStep + 1)
        setLoading(false)
      }
    }
    setLoading(true)
    img.src = gStepMask(currentStep)
  }, [stepCount, floor, currentStep, gStepMask, readMaskImage, loading])

  const getHouseSteps = useCallback(
    (houseId) => {
      const houseSteps = []
      for (let i = 0; i < stepCount; ++i) {
        const step = { centroid: [0, 0], area: [] }
        houseSteps.push(step)
        const s = steps[i].find((step) => step.houseId === houseId)
        if (s) {
          step.centroid = s.centroid
          step.area.push(s.contour)
        }
      }
      return houseSteps
    },
    [steps, stepCount]
  )

  useEffect(() => {
    if (currentStep === stepCount) {
      const floorHouses = gFloorHouses(floor)
      const houses = []
      for (let house of floorHouses) {
        houses.push({ houseId: house, steps: getHouseSteps(house) })
      }
      setText(
        `export const floor_${floor}_contours =` +
          JSON.stringify(houses, null, 2)
      )
    }
  }, [steps, currentStep, floor, gFloorHouses, stepCount, getHouseSteps])

  return (
    <>
      <button onClick={handleIniciar}>Iniciar</button>
      <canvas className={classes.canvas} ref={canvasRef} />
      <pre className={classes.text}>{text}</pre>
    </>
  )
}
export default RContourGenerator
