import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import RBarButton from './RBarButton'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {
  setBarVisible,
  barVisibleSelector
} from '../../../modules/reducerRotator'
import useMeasure from 'react-use-measure'

const useStyles = makeStyles((theme) => ({
  root: { marginLeft: '10px' }
}))

const RFabButton = (props) => {
  const [ref, bounds] = useMeasure()
  const classes = useStyles()
  const barVisible = useSelector(barVisibleSelector)

  const dispatch = useDispatch()
  const handleClick = useCallback(
    (e) => {
      console.log(bounds)
      e.stopPropagation()
      dispatch(setBarVisible(!barVisible))
    },
    [dispatch, bounds, barVisible]
  )

  return (
    <div ref={ref}>
      <RBarButton
        className={classes.root}
        color="primary"
        tooltip={barVisible ? 'Hide' : 'Show'}
        onClick={handleClick}
        disabled={false}
      >
        {barVisible ? (
          <ArrowDownwardIcon fontSize="inherit" />
        ) : (
          <ArrowUpwardIcon fontSize="inherit" />
        )}
      </RBarButton>
    </div>
  )
}
export default RFabButton
