export const vector = (x = 0, y = 0) => ({ x: x, y: y })
export const vectorFromPoints = (startPoint, endPoint) => {
  return { x: endPoint.x - startPoint.x, y: endPoint.y - startPoint.y }
}
export const addVectors = (v0, v1) => ({ x: v0.x + v1.x, y: v0.y + v1.y })
export const scaleVector = (v, scale) => {
  return { x: v.x * scale, y: v.y * scale }
}
export const rotateVector = (v, rad) => {
  const cos = Math.cos(rad)
  const sin = Math.sin(rad)
  return { x: v.x * cos - v.y * sin, y: v.x * sin + v.y * cos }
}

export const radToDegree = (rad) => {
  return rad * (180 / Math.PI)
}

export const degreeToRad = (degree) => {
  return degree * (Math.PI / 180)
}

export const angleBetweenVectors = (v0, v1) => {
  return Math.atan2(v0.x * v1.y - v0.y * v1.x, v0.x * v1.x + v0.y * v1.y)
}

export const rangeValue = (value, min, max) =>
  Math.min(Math.max(value, min), max)

export const boxCenter = (top, left, width, height) => {
  return vector(left + width / 2, top + height / 2)
}

export const rgbaColor = (r = 0, g = 0, b = 0, a = 0) => {
  return { r: r, g: g, b: b, a: a }
}

export const similarColors = (color0, color1) => {
  return color0.r === color1.r && color0.g === color1.g && color0.b === color1.b
}

// export const similarColors = (color0, color1) => {
//   const delta = 1
//   return (
//     color0.r >= color1.r - delta &&
//     color0.r < color1.r + delta &&
//     color0.g >= color1.g - delta &&
//     color0.g < color1.g + delta &&
//     color0.b >= color1.b - delta &&
//     color0.b < color1.b + delta
//   )
// }

// export const similarColors = (color0, color1) => {
//   // calculate differences between reds, greens and blues
//   var r = 255 - Math.abs(color0.r - color1.r)
//   var g = 255 - Math.abs(color0.g - color1.g)
//   var b = 255 - Math.abs(color0.b - color1.b)
//   // limit differences between 0 and 1
//   r /= 255
//   g /= 255
//   b /= 255
//   // 0 means opposit colors, 1 means same colors
//   return (r + g + b) / 3 > 0.9
// }

export const isBlack = (color) => {
  return color.r === 0 && color.b === 0 && color.b === 0
}
export const isWhite = (color) => {
  return color.r === 255 && color.b === 255 && color.b === 255
}

export const getColor = (index, data) => {
  const color = {
    r: data[index],
    g: data[index + 1],
    b: data[index + 2],
    a: data[index + 3]
  }
  return color
}

export const centroid = (points) => {
  if (points.length) {
    let centroid = points.reduce(
      (accumulator, point) => {
        accumulator[0] += point[0]
        accumulator[1] += point[1]
        return accumulator
      },
      [0, 0]
    )
    return [centroid[0] / points.length, centroid[1] / points.length]
  }
  return [0, 0]
}
