import React, { useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { nStepSelector, imagesSelector } from '../../modules/reducerRotator'
import { toggleHouseSelected } from '../../modules/reducerHouses'
import {
  setMaskData,
  getVisibleContours,
  getHouseAtPoint,
  mouseOverHouse,
  setFloor,
  maskImagesSelector,
  hoverIdSelector,
  getStepContours
} from '../../modules/reducerMasks'

const useStyles = makeStyles((theme) => ({
  mainCanvas: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  maskCanvas: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    opacity: 0
  }
}))

function RCanvas() {
  const mainCanvasRef = useRef(null)
  const maskCanvasRef = useRef(null)
  const nStep = useSelector(nStepSelector)
  const masks = useSelector(maskImagesSelector)
  const visibleContours = useSelector(getVisibleContours)
  const stepContours = useSelector(getStepContours)
  const gHouseAtPoint = useSelector(getHouseAtPoint)
  const images = useSelector(imagesSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => dispatch(setFloor('f0')), [dispatch])

  // useEffect(() => {
  //   //Lee datos mascara
  //   const maskCanvas = maskCanvasRef.current
  //   const mainCanvas = mainCanvasRef.current
  //   var img = new Image()
  //   img.onload = function () {
  //     //TODO maskCanvas,mainCanvas puden ser nulos?
  //     maskCanvas.width = img.naturalWidth
  //     maskCanvas.height = img.naturalHeight
  //     mainCanvas.width = img.naturalWidth
  //     mainCanvas.height = img.naturalHeight
  //     const ctx = maskCanvas.getContext('2d')
  //     ctx.clearRect(0, 0, maskCanvas.width, maskCanvas.height)
  //     ctx.drawImage(img, 0, 0)
  //     dispatch(
  //       setMaskData(
  //         ctx.getImageData(0, 0, maskCanvas.width, maskCanvas.height),
  //         nStep
  //       )
  //     )
  //   }
  //   img.src = masks[nStep]
  // }, [dispatch, masks, nStep])

  const rgbaString = (color, alpha) =>
    `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`

  const drawContour = useCallback((contour, color, ctx) => {
    const points = contour
    // const points = window.simplify(contour, 0.5, true)
    // console.log('points', contour, points)
    if (points.length > 0) {
      ctx.beginPath()
      ctx.moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i][0], points[i][1])
      }
      ctx.closePath()
      ctx.strokeStyle = rgbaString(color, 1)
      ctx.stroke()
      ctx.fillStyle = rgbaString(color, 0.5)
      ctx.fill()
    }
  }, [])

  const drawHouseContours = useCallback(
    (houseContours, ctx) => {
      //Contornos
      for (let contour of houseContours.contour.area) {
        drawContour(contour, houseContours.color, ctx)
      }
      //Texto
      ctx.font = '20px Arial'
      ctx.fillStyle = 'black'
      ctx.fillText(
        houseContours.houseId,
        houseContours.contour.centroid[0],
        houseContours.contour.centroid[1]
      )
    },
    [drawContour]
  )

  /*
  useEffect(() => {
    //Dibuja contornos
    const canvas = mainCanvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    for (let houseContour of visibleContours) {
      drawHouseContours(houseContour, ctx)
    }
  }, [dispatch, drawHouseContours, visibleContours])
  */
  useEffect(() => {
    const mainCanvas = mainCanvasRef.current
    var img = new Image()
    img.onload = function () {
      const maskCanvas = mainCanvasRef.current
      maskCanvas.width = img.naturalWidth
      maskCanvas.height = img.naturalHeight
      const ctxMask = maskCanvas.getContext('2d')
      ctxMask.clearRect(0, 0, maskCanvas.width, maskCanvas.height)
      for (let houseContour of stepContours) {
        drawHouseContours(houseContour, ctxMask)
      }
      dispatch(
        setMaskData(
          ctxMask.getImageData(0, 0, maskCanvas.width, maskCanvas.height),
          nStep
        )
      )
      //
      mainCanvas.width = img.naturalWidth
      mainCanvas.height = img.naturalHeight
      const ctx = mainCanvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      // console.log('visibleContours', visibleContours)
      for (let houseContour of visibleContours) {
        drawHouseContours(houseContour, ctx)
      }
    }
    img.src = images[nStep]
  }, [
    drawHouseContours,
    visibleContours,
    images,
    nStep,
    dispatch,
    stepContours
  ])

  /*
useEffect(() => {
  //Lee datos mascara
  const maskCanvas = maskCanvasRef.current
  const mainCanvas = mainCanvasRef.current
  var img = new Image()
  img.onload = function () {
    //TODO maskCanvas,mainCanvas puden ser nulos?
    maskCanvas.width = img.naturalWidth
    maskCanvas.height = img.naturalHeight
    mainCanvas.width = img.naturalWidth
    mainCanvas.height = img.naturalHeight
    const ctx = maskCanvas.getContext('2d')
    ctx.clearRect(0, 0, maskCanvas.width, maskCanvas.height)
    ctx.drawImage(img, 0, 0)
    dispatch(
      setMaskData(
        ctx.getImageData(0, 0, maskCanvas.width, maskCanvas.height),
        nStep
      )
    )
  }
  img.src = masks[nStep]
}, [dispatch, masks, nStep])
*/

  const handleMove = useCallback(
    (e) => {
      const house = gHouseAtPoint({ x: e.pageX, y: e.pageY })
      dispatch(mouseOverHouse(house))
    },
    [dispatch, gHouseAtPoint]
  )

  const handleClick = useCallback(
    (e) => {
      const house = gHouseAtPoint({ x: e.pageX, y: e.pageY })
      console.log('click', house)
      if (house !== '') {
        dispatch(toggleHouseSelected(house))
      }
    },
    [dispatch, gHouseAtPoint]
  )
  return (
    <>
      <canvas
        className={classes.mainCanvas}
        ref={mainCanvasRef}
        onMouseMove={handleMove}
        onClick={handleClick}
      />
      <canvas
        className={classes.maskCanvas}
        ref={maskCanvasRef}
        onMouseMove={handleMove}
        onClick={handleClick}
      />
    </>
  )
}
export default RCanvas
