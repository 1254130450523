import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { hoverIdSelector } from '../../modules/reducerMasks'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
    height: '200px'
  }
}))

const RTooltip = () => {
  const classes = useStyles()
  const hoverId = useSelector(hoverIdSelector)
  return hoverId !== '' ? (
    <ReactTooltip>
      <div className={classes.root}>Descripcion y demas</div>
      <button>Boton</button>
    </ReactTooltip>
  ) : null
}
export default RTooltip
