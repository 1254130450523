import { createSelector } from 'reselect'
import { scaleSelector, getTopLeftImage, nStepSelector } from './reducerRotator'
import { selectedHousesSelector, getFloorHouses } from './reducerHouses'
import {
  rgbaColor,
  getColor,
  similarColors
} from '../Components/Rotator/mathFunctions'
import { floor_f0_contours } from './prueba'

export const SET_FLOOR = 'SET_FLOOR'
export const SET_MASK_IMAGE_DATA = 'SET_MASK_IMAGE_DATA'
export const MOUSE_OVER_HOUSE = 'MOUSE_OVER_HOUSE'

export const SELECTED_COLOR = rgbaColor(255, 0, 0, 255)
export const HOVER_COLOR = rgbaColor(0, 255, 0, 255)
export const SELECTED_AND_HOVER_COLOR = rgbaColor(255, 255, 0, 255)

const generateFloorImages = (floorId) => {
  let images = []
  for (let i = 1; i < 24; ++i) {
    if (i > 9) {
      images.push(`./images/GiroCasa/mascara/EDIFICIO_PLANTA_04.2_00${i}.png`)
    } else {
      images.push(`./images/GiroCasa/mascara/EDIFICIO_PLANTA_04.2_000${i}.png`)
    }
  }
  return images
}
const readFloorContours = (floorId) => {
  return floor_f0_contours
}

const initialState = {
  floorId: '',
  floorMaskImages: [],
  housesMaskColor: [
    { houseId: 'h0', rgba: rgbaColor(8, 255, 0, 255) }, //Verde
    { houseId: 'h1', rgba: rgbaColor(255, 162, 0, 255) }, //Naranja
    { houseId: 'h2', rgba: rgbaColor(0, 18, 255, 255) }, //Azul
    { houseId: 'h3', rgba: rgbaColor(255, 254, 0, 255) } //Amarillo
  ],
  maskData: null,
  maskDataStep: -1, //TODO Esto no haca falta mas
  floorContours: [],
  hoverId: ''
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_FLOOR: {
      return {
        ...state,
        floorId: action.floorId,
        floorMaskImages: generateFloorImages(action.floorId),
        floorContours: readFloorContours(action.floorId),
        hoverId: '',
        maskData: null,
        maskDataStep: -1
      }
    }
    case SET_MASK_IMAGE_DATA:
      return {
        ...state,
        maskData: action.data,
        maskDataStep: action.step
      }
    case MOUSE_OVER_HOUSE:
      //TODO
      return {
        ...state,
        hoverId: action.house
      }
    default:
      return state
  }
}
export default changeState

export const setFloor = (floorId) => ({
  type: SET_FLOOR,
  floorId
})

export const setMaskData = (data, step) => ({
  type: SET_MASK_IMAGE_DATA,
  data,
  step
})

export const mouseOverHouse = (house) => ({
  type: MOUSE_OVER_HOUSE,
  house
})

export const floorSelector = (state) => state.masks.floorId
export const maskImagesSelector = (state) => state.masks.floorMaskImages
export const housesMaskColorSelector = (state) => state.masks.housesMaskColor
export const maskDataSelector = (state) => state.masks.maskData
export const maskDataStepSelector = (state) => state.masks.maskDataStep
export const floorContoursSelector = (state) => state.masks.floorContours
export const hoverIdSelector = (state) => state.masks.hoverId

export const getStepMask = createSelector(
  [maskImagesSelector],
  (images) => (step) => {
    if (step >= 0 && step < images.length) {
      return images[step]
    }
    return null
  }
)

//Color de la mascara de la casa
export const getHouseMaskColor = createSelector(
  [housesMaskColorSelector],
  (houses) => (houseId) => {
    const house = houses.find((h) => h.houseId === houseId)
    return house ? house.rgba : rgbaColor()
  }
)

//Colores de las mascaras de las casas
export const getHousesMaskColor = createSelector(
  [housesMaskColorSelector],
  (houses) => (housesId) => {
    const houseColors = housesId.reduce((accumulator, houseId) => {
      const house = houses.find((h) => h.houseId === houseId)
      if (house) {
        accumulator.push(house)
      }
      return accumulator
    }, [])
    return houseColors
  }
)

//Colores de las mascaras de las casas de la planta actual
export const getCurrentFloorHousesMaskColors = createSelector(
  [getFloorHouses, getHousesMaskColor, floorSelector],
  (gFloorHouses, gHousesMaskColor, floorId) => {
    return gHousesMaskColor(gFloorHouses(floorId))
  }
)

//Color del pixel en la mascara actual
export const getPixelColor = createSelector(
  [maskDataSelector, scaleSelector, getTopLeftImage],
  (maskData, scale, topLeft) => (point) => {
    var descaleX = Math.round((point.x - topLeft.x) / scale)
    var descaleY = Math.round((point.y - topLeft.y) / scale)
    let index = (descaleY * maskData.width + descaleX) * 4
    return getColor(index, maskData.data)
  }
)

export const getHouseAtPoint = createSelector(
  [housesMaskColorSelector, getPixelColor],
  (houses, gPixelColor) => (point) => {
    const color = gPixelColor(point)
    // console.log(point, color)
    if (color.a === 0) {
      return ''
    }
    const house = houses.find((h) => similarColors(h.rgba, color))
    return house ? house.houseId : ''
  }
)

export const getContour = createSelector(
  [floorContoursSelector],
  (floorContours) => (houseId, nStep) => {
    const hContours = floorContours.find(
      (houseContours) => houseContours.houseId === houseId
    )
    return hContours ? hContours.steps[nStep] : null
  }
)

export const getVisibleContours = createSelector(
  [hoverIdSelector, selectedHousesSelector, getContour, nStepSelector],
  (hoverId, selectedIds, gContour, step) => {
    let contours = selectedIds.reduce((accumulator, houseId) => {
      let color
      if (hoverId === houseId) {
        color = SELECTED_AND_HOVER_COLOR
      } else {
        color = SELECTED_COLOR
      }
      const contour = gContour(houseId, step)
      if (contour) {
        accumulator.push({ houseId: houseId, contour: contour, color: color })
      }
      return accumulator
    }, [])
    if (hoverId !== '' && selectedIds.indexOf(hoverId) < 0) {
      const contour = gContour(hoverId, step)
      if (contour) {
        contours.push({
          houseId: hoverId,
          contour: contour,
          color: HOVER_COLOR
        })
      }
    }
    return contours
  }
)

export const getStepContours = createSelector(
  [getContour, nStepSelector, getFloorHouses, getHouseMaskColor, floorSelector],
  (gContour, step, getHouses, getColor, floorId) => {
    let contours = getHouses(floorId).reduce((accumulator, houseId) => {
      const contour = gContour(houseId, step)
      if (contour) {
        accumulator.push({
          houseId: houseId,
          contour: contour,
          color: getColor(houseId)
        })
      }
      return accumulator
    }, [])
    return contours
  }
)
