import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  rootBoundsSelector,
  setScale,
  setImageSize
} from '../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  img: {},
  preload: {
    display: 'none'
  }
}))

const RImageLoader = ({ nImage, imagesArray }) => {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const rootBounds = useSelector(rootBoundsSelector)
  const dispatch = useDispatch()

  const handleLoad = useCallback(
    (e) => {
      if (!loaded) {
        setLoaded(true)
        const scale = Math.min(
          rootBounds.width / e.target.naturalWidth,
          rootBounds.height / e.target.naturalHeight
        )
        dispatch(setScale(scale))
        dispatch(setImageSize(e.target.naturalWidth, e.target.naturalHeight))
      }
    },
    [loaded, dispatch, rootBounds]
  )

  return (
    <>
      <div className={classes.preload}>
        {imagesArray.map((i, index) => (
          <img key={index} src={i} alt={i} />
        ))}
      </div>
    </>
  )
}
export default RImageLoader
/*
<img
  className={classes.img}
  src={imagesArray[nImage]}
  alt={imagesArray[nImage]}
  onLoad={handleLoad}
/>
*/
