import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Rotator from './Rotator'
import RContourGenerator from './ContourGenerator/RContourGenerator'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(241, 234, 207)',
    width: '100vw',
    height: '100vh',

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const RMain = () => {
  const classes = useStyles()
  const [mode, setMode] = useState(true)
  const handleClick = () => {
    setMode(!mode)
  }
  return (
    <div className={classes.root}>
      {mode ? (
        <Rotator width={600} height={800} />
      ) : (
        <Rotator width={750} height={500} />
      )}
    </div>
  )
}
export default RMain
/*
<RContourGenerator />

{mode ? (
  <Rotator width={600} height={800} />
) : (
  <Rotator width={750} height={500} />
)}
*/
//<button onClick={handleClick}>Cambio tamaño</button>
