import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import RRotateRightButton from './RRotateRightButton'
import RRotateLeftButton from './RRotateLeftButton'
import RAutomaticRotationButton from './RAutomaticRotationButton'
import RZoomOutButton from './RZoomOutButton'
import RZoomInButton from './RZoomInButton'
import RPanButton from './RPanButton'
import RFullWindowButton from './RFullWindowButton'
import RHomeButton from './RHomeButton'
import RMenuButton from './RMenuButton'
import {} from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#c0c0c0'
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexWrap: 'wrap'
  },
  buttonGroup: {
    marginRight: '10px',
    marginLeft: '10px'
  }
}))

const RMainBar = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={props.style}>
      <ButtonGroup className={classes.buttonGroup} color="primary">
        <RMenuButton />
        <RHomeButton />
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup} color="primary">
        <RRotateRightButton />
        <RAutomaticRotationButton />
        <RRotateLeftButton />
      </ButtonGroup>

      <ButtonGroup className={classes.buttonGroup} color="primary">
        <RZoomInButton />
        <RZoomOutButton />
        <RFullWindowButton />
        <RPanButton />
      </ButtonGroup>
    </div>
  )
}
export default RMainBar
