import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  button: {
    zIndex: '10',
    backgroundColor: 'rgb(244, 231, 214)',
    fontSize: '14px',
    border: '1px solid grey',
    '&:hover': {
      background: 'rgb(217, 218, 244)'
    },
    '&:disabled': {
      background: 'rgb(244, 231, 214)'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  buttonChecked: {
    color: 'green'
  }
}))

const RBarButton = (props) => {
  const classes = useStyles()
  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        {...props}
        className={`${props.className} ${classes.button}`}
        onClick={props.onClick}
        disabled={props.disabled}
        color={props.checked ? 'secondary' : 'primary'}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}

export default RBarButton
