import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import PanToolIcon from '@material-ui/icons/PanTool'
import RBarButton from './RBarButton'
import { modeSelector, MODE, setMode } from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const RPanButton = (props) => {
  const classes = useStyles()

  const mode = useSelector(modeSelector)
  const dispatch = useDispatch()

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const handleClick = (e) => {
    if (mode === MODE.BUTTONS) {
      setModeD(MODE.PAN)
    } else if (mode === MODE.PAN) {
      setModeD(MODE.BUTTONS)
    }
  }
  return (
    <RBarButton
      {...props}
      tooltip="Pan"
      onClick={handleClick}
      disabled={mode === MODE.ROT_AUTOMATIC || mode === MODE.ROT_MOUSE}
      checked={mode === MODE.PANNING}
    >
      <PanToolIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RPanButton
