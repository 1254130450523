import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import RMainBar from './RMainBar'
import RFabButton from './RFabButton'

import { useSpring, animated } from 'react-spring'

import { barVisibleSelector } from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  rotatorBar: {
    // backgroundColor: 'rgb(205, 238, 231)',
    // backgroundColor: '#c0c0c0',
    maxWidth: '530px',

    position: 'absolute',
    margin: 'auto',
    left: '0px',
    right: '0px',
    bottom: '10px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}))

const RBar = (props) => {
  const classes = useStyles()
  const barVisible = useSelector(barVisibleSelector)

  const handleMouseDown = function (e) {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleMouseUp = function (e) {
    e.preventDefault() //Solo este. stopPropagation se deja por si se suelta
    //cuando se esta rotando/pan sobre un boton
  }

  const handleDrag = function (e) {
    e.stopPropagation()
    e.preventDefault()
  }

  const divRef = React.useRef()
  const anchura = divRef.current ? divRef.current.offsetWidth : 0
  const fabAnimation = useSpring({
    transform: barVisible
      ? 'translate(0px,0px)'
      : `translate(-${anchura / 2}px,0px)`
  })

  const toolbarAnimation = useSpring({
    marginBottom: barVisible ? 0 : -200,
    opacity: barVisible ? 1 : 0
  })

  return (
    <div
      className={classes.rotatorBar}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onDragStart={handleDrag}
    >
      <animated.div style={toolbarAnimation} ref={divRef}>
        <RMainBar />
      </animated.div>

      <animated.div style={fabAnimation}>
        <RFabButton />
      </animated.div>
    </div>
  )
}
export default RBar
