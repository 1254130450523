import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import RBarButton from './RBarButton'
import { modeSelector, MODE, resetView } from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const RHomeButton = (props) => {
  const classes = useStyles()

  const mode = useSelector(modeSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(resetView())
  }, [dispatch])

  return (
    <RBarButton
      {...props}
      tooltip="Default view"
      onClick={handleClick}
      disabled={mode === MODE.ROT_AUTOMATIC || mode === MODE.ROT_MOUSE}
    >
      <HomeIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RHomeButton
