import { createSelector } from 'reselect'
import {
  vector,
  vectorFromPoints,
  scaleVector,
  addVectors,
  boxCenter,
  rangeValue
} from '../Components/Rotator/mathFunctions'

export const ZOOM_FACTOR = 0.1
export const ROTATION_VELOCITY = 150
export const SCALE_RANGE = [0.1, 5]

export const SET_MODE = 'SET_MODE'

export const RESET_VIEW = 'RESET_VIEW'

export const ROTATE = 'ROTATE'
export const SET_SCALE = 'SET_SCALE'
export const ZOOM_AT_POINT = 'ZOOM_AT_POINT'
export const SET_TRANSLATION = 'SET_TRANSLATION'

export const SET_BAR_VISIBLE = 'SET_BAR_VISIBLE'
export const SET_BOUNDS = 'SET_BOUNDS'
export const SET_IMAGE_SIZE = 'SET_IMAGE_SIZE'

const generateImages = () => {
  let images = []
  for (let i = 1; i < 24; ++i) {
    if (i > 9) {
      images.push(`./images/GiroCasa/base/EDIFICIO_PLANTA_04.2_00${i}.jpg`)
    } else {
      images.push(`./images/GiroCasa/base/EDIFICIO_PLANTA_04.2_000${i}.jpg`)
    }
  }
  return images
}

export const MODE = {
  BUTTONS: 'BUTTONS',
  ROT_AUTOMATIC: 'ROT_AUTOMATIC',
  ROT_MOUSE: 'ROT_MOUSE',
  PAN: 'PAN',
  PANNING: 'PANNING'
}

const initialState = {
  mode: MODE.BUTTONS,
  nStep: 0,
  images: generateImages(),
  scale: 1,
  translation: vector(),
  barVisible: true,
  rootBounds: {},
  imageSize: { width: 0, height: 0 }
}

const getNextStep = (currentStep, stepInc, nSteps) => {
  if (nSteps <= 1) {
    return 0
  }
  currentStep += stepInc
  currentStep %= nSteps
  if (currentStep < 0) {
    return nSteps + currentStep
  }
  return currentStep
}

const changeState = (state = initialState, action) => {
  //console.log(action)
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        mode: action.mode
      }
    case SET_BAR_VISIBLE:
      return {
        ...state,
        barVisible: action.visible
      }

    case RESET_VIEW: {
      return {
        ...state,
        nStep: 0,
        scale: 1,
        translation: vector()
      }
    }

    case ROTATE: {
      return {
        ...state,
        nStep: getNextStep(state.nStep, action.steps, state.images.length)
      }
    }

    case SET_SCALE:
      return {
        ...state,
        scale: rangeValue(action.scale, SCALE_RANGE[0], SCALE_RANGE[1])
      }
    case ZOOM_AT_POINT:
      const newScale = action.zoomIn
        ? Math.min(state.scale + ZOOM_FACTOR, SCALE_RANGE[1])
        : Math.max(state.scale - ZOOM_FACTOR, SCALE_RANGE[0])
      if (newScale === state.scale) {
        return state
      }
      const imageCenter = addVectors(
        boxCenter(
          state.rootBounds.top,
          state.rootBounds.left,
          state.rootBounds.width,
          state.rootBounds.height
        ),
        state.translation
      )

      const zoomPoint = vectorFromPoints(imageCenter, action.point)

      //Quita escalado
      const zoomPointOriginal = scaleVector(zoomPoint, 1 / state.scale)
      //Nuevas coordenadas del punto con la nueva escala
      const newZoomPoint = scaleVector(zoomPointOriginal, newScale)
      //const newZoomPoint = scaleVector(zoomPoint, newScale / scale)
      //Incremento entre los dos puntos
      const inc = vectorFromPoints(zoomPoint, newZoomPoint)
      //Desplazamiento para corregir el incremento
      const newTranslation = vectorFromPoints(inc, state.translation)
      return {
        ...state,
        scale: newScale,
        translation: newTranslation
      }

    case SET_TRANSLATION:
      return {
        ...state,
        translation: action.translation
      }
    case SET_BOUNDS:
      return {
        ...state,
        rootBounds: action.rootBounds
      }
    case SET_IMAGE_SIZE:
      return {
        ...state,
        imageSize: action.size
      }

    default:
      return state
  }
}
export default changeState

export const setMode = (value) => ({
  type: SET_MODE,
  mode: value
})

export const rotate = (steps) => ({
  type: ROTATE,
  steps: steps
})

export const rotateLeft = (steps) => ({
  type: ROTATE,
  steps: -steps
})

export const rotateRight = (steps) => ({
  type: ROTATE,
  steps: steps
})

export const setScale = (value) => ({
  type: SET_SCALE,
  scale: value
})

export const zoomAtGlobalPoint = (zoomIn, point) => ({
  type: ZOOM_AT_POINT,
  point: point,
  zoomIn: zoomIn
})

export const setTranslation = (value) => ({
  type: SET_TRANSLATION,
  translation: value
})

export const resetView = () => ({
  type: RESET_VIEW
})

export const setBarVisible = (value) => ({
  type: SET_BAR_VISIBLE,
  visible: value
})

export const setRootBounds = (value) => ({
  type: SET_BOUNDS,
  rootBounds: value
})

export const setImageSize = (width, height) => ({
  type: SET_IMAGE_SIZE,
  size: { width, height }
})

export const modeSelector = (state) => state.rotator.mode
export const nStepSelector = (state) => state.rotator.nStep
export const imagesSelector = (state) => state.rotator.images
export const scaleSelector = (state) => state.rotator.scale
export const translationSelector = (state) => state.rotator.translation
export const barVisibleSelector = (state) => state.rotator.barVisible
export const lateralVisibleSelector = (state) => state.rotator.lateralVisible
export const rootBoundsSelector = (state) => state.rotator.rootBounds
export const imageSizeSelector = (state) => state.rotator.imageSize

export const getStepCount = createSelector(
  [imagesSelector],
  (images) => images.length
)

export const getStepLength = (state) => {
  const images = imagesSelector(state)
  if (images.length <= 1) {
    return 360
  }
  return 360 / images.length
}

export const getContainerCenter = createSelector(
  [rootBoundsSelector],
  (containerBounds) => {
    return boxCenter(
      containerBounds.y,
      containerBounds.x,
      containerBounds.width,
      containerBounds.height
    )
  }
)

export const getContainerTopLeft = createSelector(
  [rootBoundsSelector],
  (containerBounds) => {
    return { x: containerBounds.x, y: containerBounds.y }
  }
)

export const getImageCenter = createSelector(
  [getContainerCenter, translationSelector, scaleSelector],
  (containerCenter, translation, scale) => {
    return addVectors(containerCenter, translation)
  }
)

//Coordenadas topLeft de la imagen
export const getTopLeftImage = createSelector(
  [getContainerCenter, scaleSelector, translationSelector, imageSizeSelector],
  (rootCenter, scale, translation, size) => {
    let x = rootCenter.x - (scale * size.width) / 2 + translation.x
    let y = rootCenter.y - (scale * size.height) / 2 + translation.y
    return { x: x, y: y }
  }
)
