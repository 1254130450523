import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'

import RBar from './Bar/RBar'
import ImageLoader from './RImageLoader'
import RCanvas from './RCanvas'
import RTooltip from './RTooltip'

import {
  vector,
  addVectors,
  vectorFromPoints,
  radToDegree,
  degreeToRad,
  angleBetweenVectors,
  rotateVector
} from './mathFunctions'

import {
  imagesSelector,
  modeSelector,
  setMode,
  getStepLength,
  nStepSelector,
  rotate,
  translationSelector,
  setTranslation,
  scaleSelector,
  zoomAtGlobalPoint,
  setRootBounds,
  getImageCenter,
  MODE
} from '../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: 'rgb(136, 213, 238)',
    backgroundColor: '#c0c0c0',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    // width: ({ width }) => `${width}px`,
    // height: ({ height }) => `${height}px`,
    overflow: 'hidden',
    cursor: ({ mode }) => (mode === 'PANNING' ? 'move' : '')
  },
  transformations: {
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    transform: ({ translation, scale }) =>
      `translate(${translation.x}px, ${translation.y}px) scale(${scale})`
  },
  rotateIcon: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transform: 'scale(10)'
  },
  hoverHouse: { position: 'absolute', top: 0, right: 0, fontSize: '32px' }
}))

const Rotator = ({ width, height }) => {
  const [clickPoint, setClickPoint] = useState(vector())
  const [initialTranslation, setInitialTranslation] = React.useState(vector())
  const [rootRef, rootBounds] = useMeasure()
  const dispatch = useDispatch()
  const images = useSelector(imagesSelector)
  const nStep = useSelector(nStepSelector)
  const mode = useSelector(modeSelector)
  const stepLengthDegree = useSelector(getStepLength)
  const scale = useSelector(scaleSelector)
  const translation = useSelector(translationSelector)
  const imageCenter = useSelector(getImageCenter)
  const classes = useStyles({ mode, translation, scale, width, height })

  useEffect(() => {
    dispatch(setRootBounds(rootBounds))
  }, [dispatch, rootBounds])

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const rotateD = useCallback(
    (value) => {
      dispatch(rotate(value))
    },
    [dispatch]
  )

  const setTranslationD = useCallback(
    (value) => {
      dispatch(setTranslation(value))
    },
    [dispatch]
  )

  const zoomAtGlobalPointD = useCallback(
    (zoomIn, globalPoint) => {
      dispatch(zoomAtGlobalPoint(zoomIn, globalPoint))
    },
    [dispatch]
  )

  const handleDrag = function (e) {
    e.preventDefault()
  }

  const handleWheel = (e) => {
    if (e.deltaY) {
      zoomAtGlobalPointD(e.deltaY > 0, vector(e.clientX, e.clientY))
    }
  }
  const handleMouseDown = function (e) {
    if (e.button === 0) {
      //Boton izquierdo
      setClickPoint(vector(e.clientX, e.clientY))
      if (mode === MODE.BUTTONS) {
        setModeD(MODE.ROT_MOUSE)
      } else if (mode === MODE.PAN) {
        setInitialTranslation(translation)
        setModeD(MODE.PANNING)
      }
    }
  }

  const handleMouseMove = (e) => {
    if (mode === MODE.PANNING) {
      const inc = vectorFromPoints(clickPoint, vector(e.pageX, e.pageY))
      setTranslationD(addVectors(initialTranslation, inc))
    } else if (mode === MODE.ROT_MOUSE) {
      //Giro con el raton.
      //Calcula angulo entre el punto que se pincho y la posicion actual. El
      //angulo se calcula con respecto al centro de la imagen
      const vStart = vectorFromPoints(imageCenter, clickPoint)
      const vEnd = vectorFromPoints(imageCenter, vector(e.pageX, e.pageY))

      const angle = radToDegree(angleBetweenVectors(vStart, vEnd))
      const absAngle = Math.abs(angle)
      if (absAngle > stepLengthDegree) {
        const nSteps =
          Math.sign(angle) * Math.floor(absAngle / stepLengthDegree)
        const rotatedAngle = nSteps * stepLengthDegree
        //Mueve punto de inicio el angulo que se va a girar
        const point = rotateVector(vStart, degreeToRad(rotatedAngle))
        setClickPoint(addVectors(imageCenter, point))
        rotateD(nSteps)
      }
    }
  }

  const handleMouseUp = function (e) {
    if (mode === MODE.ROT_MOUSE) {
      setModeD(MODE.BUTTONS)
    } else if (mode === MODE.PANNING) {
      setModeD(MODE.PAN)
    }
  }

  const handleLeave = function (e) {
    if (mode === MODE.ROT_MOUSE || mode === MODE.PANNING) setModeD(MODE.BUTTONS)
  }

  return (
    <div
      ref={rootRef}
      className={classes.root}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onDragStart={handleDrag}
      onWheel={handleWheel}
      onMouseLeave={handleLeave}
      data-tip
    >
      <div className={classes.transformations}>
        <RCanvas />
        <ImageLoader nImage={nStep} imagesArray={images} />
      </div>

      <RBar />
    </div>
  )
}
export default Rotator

/*
<ImageLoader nImage={nStep} imagesArray={images} />
<RTooltip />
<div className={classes.hoverHouse}>Hover house:{hoverHouse}</div>
  <RotateRightIcon className={classes.rotateIcon} />
*/
