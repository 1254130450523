import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import RBarButton from './RBarButton'
import {
  modeSelector,
  MODE,
  setLateralVisible
} from '../../../modules/reducerRotator'

const RMenuButton = (props) => {
  const mode = useSelector(modeSelector)
  const dispatch = useDispatch()

  // const handleClick = useCallback(() => {
  //   dispatch(setLateralVisible(!lateralVisible))
  // }, [dispatch, lateralVisible])

  return (
    <RBarButton {...props} tooltip="Menu" disabled={false}>
      <MenuIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RMenuButton
