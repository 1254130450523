import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RBarButton from './RBarButton'
import { modeSelector, MODE, rotateLeft } from '../../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const RRotateLeftButton = (props) => {
  const classes = useStyles()

  const mode = useSelector(modeSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(rotateLeft(1))
  }, [dispatch])

  return (
    <RBarButton
      {...props}
      tooltip="Rotate left"
      onClick={handleClick}
      disabled={mode !== MODE.BUTTONS}
    >
      <RotateLeftIcon fontSize="inherit" />
    </RBarButton>
  )
}
export default RRotateLeftButton
