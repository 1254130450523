import { createSelector } from 'reselect'

export const SET_HOUSE_SELECTED = 'SET_HOUSE_SELECTED'
export const TOGGLE_HOUSE_SELECTED = 'TOGGLE_HOUSE_SELECTED'

const initialState = {
  floors: [
    { id: 'f0', name: 'Planta 0', houses: ['h0', 'h1', 'h2', 'h3'] },
    { id: 'f1', name: 'Planta 1', houses: [] },
    { id: 'f2', name: 'Planta 2', houses: [] },
    { id: 'f3', name: 'Planta 3', houses: [] },
    { id: 'f4', name: 'Planta 4', houses: [] },
    { id: 'f5', name: 'Planta 5', houses: [] }
  ],
  houses: [
    { id: 'h0', name: 'Casa 0' },
    { id: 'h1', name: 'Casa 1' },
    { id: 'h2', name: 'Casa 2' },
    { id: 'h3', name: 'Casa 3' }
  ],
  selected: ['h1', 'h2', 'h3', 'h0']
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_HOUSE_SELECTED: {
      const newSelection = [...state.selected]
      const idx = newSelection.indexOf(action.id)
      if (action.select && idx < 0) {
        newSelection.push(action.id)
      } else if (!action.select && idx >= 0) {
        newSelection.splice(idx, 1)
      }
      return {
        ...state,
        selected: newSelection
      }
    }
    case TOGGLE_HOUSE_SELECTED:
      const newSelection = [...state.selected]
      const idx = newSelection.indexOf(action.id)
      if (idx < 0) {
        newSelection.push(action.id)
      } else {
        newSelection.splice(idx, 1)
      }
      return {
        ...state,
        selected: newSelection
      }

    default:
      return state
  }
}
export default changeState

export const setHouseSelected = (id, select) => ({
  type: SET_HOUSE_SELECTED,
  id: id,
  select: select
})
export const toggleHouseSelected = (id) => ({
  type: TOGGLE_HOUSE_SELECTED,
  id: id
})

export const selectHouse = (id) => ({
  type: SET_HOUSE_SELECTED,
  id: id,
  select: true
})

export const deselectHouse = (id) => ({
  type: SET_HOUSE_SELECTED,
  id: id,
  select: false
})

export const housesSelector = (state) => state.houses.houses
export const floorsSelector = (state) => state.houses.floors
export const selectedHousesSelector = (state) => state.houses.selected

export const getFloorHouses = createSelector(
  [floorsSelector],
  (floors) => (floorId) => {
    const floor = floors.find((f) => f.id === floorId)
    return floor ? floor.houses : []
  }
)
