

export const floor_f0_contours =[
  {
    "houseId": "h0",
    "steps": [
      {
        "centroid": [
          761.9230769230769,
          429.3076923076923
        ],
        "area": [
          [
            [
              860,
              413
            ],
            [
              838,
              444
            ],
            [
              832,
              451
            ],
            [
              830,
              455
            ],
            [
              778,
              527
            ],
            [
              776,
              527
            ],
            [
              772,
              525
            ],
            [
              710,
              490
            ],
            [
              703,
              485
            ],
            [
              701,
              485
            ],
            [
              694,
              480
            ],
            [
              672,
              469
            ],
            [
              660,
              461
            ],
            [
              653,
              458
            ],
            [
              630,
              445
            ],
            [
              717,
              334
            ],
            [
              724,
              326
            ],
            [
              726,
              326
            ],
            [
              730,
              328
            ],
            [
              808,
              369
            ],
            [
              809,
              370
            ],
            [
              806,
              376
            ],
            [
              808,
              385
            ],
            [
              853,
              408
            ],
            [
              860,
              412
            ],
            [
              860,
              413
            ]
          ]
        ]
      },
      {
        "centroid": [
          754,
          422.10526315789474
        ],
        "area": [
          [
            [
              883,
              431
            ],
            [
              837,
              476
            ],
            [
              831,
              481
            ],
            [
              812,
              500
            ],
            [
              778,
              532
            ],
            [
              769,
              525
            ],
            [
              763,
              519
            ],
            [
              759,
              517
            ],
            [
              712,
              479
            ],
            [
              704,
              471
            ],
            [
              686,
              458
            ],
            [
              672,
              445
            ],
            [
              665,
              441
            ],
            [
              655,
              433
            ],
            [
              650,
              428
            ],
            [
              661,
              419
            ],
            [
              667,
              413
            ],
            [
              669,
              412
            ],
            [
              678,
              403
            ],
            [
              681,
              401
            ],
            [
              697,
              386
            ],
            [
              699,
              385
            ],
            [
              707,
              377
            ],
            [
              709,
              376
            ],
            [
              717,
              368
            ],
            [
              719,
              367
            ],
            [
              728,
              358
            ],
            [
              739,
              349
            ],
            [
              766,
              324
            ],
            [
              839,
              378
            ],
            [
              841,
              380
            ],
            [
              841,
              381
            ],
            [
              837,
              385
            ],
            [
              836,
              387
            ],
            [
              838,
              397
            ],
            [
              841,
              398
            ],
            [
              883,
              429
            ],
            [
              883,
              431
            ]
          ]
        ]
      },
      {
        "centroid": [
          828.047619047619,
          438.5238095238095
        ],
        "area": [
          [
            [
              904,
              451
            ],
            [
              889,
              462
            ],
            [
              857,
              483
            ],
            [
              824,
              506
            ],
            [
              781,
              535
            ],
            [
              778,
              536
            ],
            [
              765,
              522
            ],
            [
              726,
              476
            ],
            [
              673,
              416
            ],
            [
              673,
              415
            ],
            [
              808,
              329
            ],
            [
              871,
              395
            ],
            [
              871,
              396
            ],
            [
              869,
              398
            ],
            [
              868,
              398
            ],
            [
              866,
              401
            ],
            [
              864,
              402
            ],
            [
              865,
              411
            ],
            [
              866,
              413
            ],
            [
              867,
              413
            ],
            [
              904,
              451
            ]
          ]
        ]
      },
      {
        "centroid": [
          852.375,
          431.75
        ],
        "area": [
          [
            [
              920,
              476
            ],
            [
              777,
              541
            ],
            [
              698,
              406
            ],
            [
              701,
              404
            ],
            [
              726,
              393
            ],
            [
              849,
              340
            ],
            [
              898,
              414
            ],
            [
              898,
              416
            ],
            [
              889,
              421
            ],
            [
              888,
              429
            ],
            [
              890,
              431
            ],
            [
              892,
              435
            ],
            [
              895,
              438
            ],
            [
              896,
              441
            ],
            [
              901,
              447
            ],
            [
              920,
              476
            ]
          ]
        ]
      },
      {
        "centroid": [
          854.7916666666666,
          467.625
        ],
        "area": [
          [
            [
              930,
              503
            ],
            [
              818,
              534
            ],
            [
              801,
              538
            ],
            [
              793,
              541
            ],
            [
              776,
              545
            ],
            [
              775,
              544
            ],
            [
              772,
              536
            ],
            [
              760,
              502
            ],
            [
              725,
              400
            ],
            [
              726,
              399
            ],
            [
              741,
              395
            ],
            [
              887,
              357
            ],
            [
              892,
              367
            ],
            [
              922,
              439
            ],
            [
              922,
              440
            ],
            [
              920,
              440
            ],
            [
              919,
              441
            ],
            [
              914,
              442
            ],
            [
              910,
              444
            ],
            [
              910,
              453
            ],
            [
              921,
              479
            ],
            [
              922,
              483
            ],
            [
              929,
              498
            ],
            [
              930,
              503
            ]
          ]
        ]
      },
      {
        "centroid": [
          849.7241379310345,
          506.7241379310345
        ],
        "area": [
          [
            [
              940,
              467
            ],
            [
              939,
              468
            ],
            [
              928,
              469
            ],
            [
              924,
              471
            ],
            [
              936,
              531
            ],
            [
              933,
              532
            ],
            [
              888,
              537
            ],
            [
              875,
              537
            ],
            [
              875,
              538
            ],
            [
              870,
              539
            ],
            [
              861,
              540
            ],
            [
              851,
              540
            ],
            [
              851,
              541
            ],
            [
              815,
              545
            ],
            [
              803,
              545
            ],
            [
              802,
              546
            ],
            [
              787,
              548
            ],
            [
              775,
              548
            ],
            [
              775,
              549
            ],
            [
              773,
              549
            ],
            [
              769,
              522
            ],
            [
              769,
              513
            ],
            [
              768,
              512
            ],
            [
              767,
              508
            ],
            [
              752,
              398
            ],
            [
              813,
              391
            ],
            [
              923,
              380
            ],
            [
              940,
              464
            ],
            [
              940,
              467
            ]
          ]
        ]
      },
      {
        "centroid": [
          887.9,
          456.8333333333333
        ],
        "area": [
          [
            [
              953,
              498
            ],
            [
              936,
              498
            ],
            [
              935,
              499
            ],
            [
              935,
              513
            ],
            [
              936,
              522
            ],
            [
              935,
              523
            ],
            [
              936,
              539
            ],
            [
              935,
              541
            ],
            [
              936,
              560
            ],
            [
              935,
              561
            ],
            [
              770,
              553
            ],
            [
              771,
              528
            ],
            [
              780,
              400
            ],
            [
              791,
              401
            ],
            [
              807,
              401
            ],
            [
              811,
              402
            ],
            [
              828,
              402
            ],
            [
              834,
              403
            ],
            [
              850,
              403
            ],
            [
              852,
              404
            ],
            [
              874,
              404
            ],
            [
              875,
              405
            ],
            [
              895,
              405
            ],
            [
              903,
              406
            ],
            [
              918,
              406
            ],
            [
              919,
              407
            ],
            [
              939,
              407
            ],
            [
              942,
              408
            ],
            [
              953,
              408
            ],
            [
              953,
              498
            ]
          ]
        ]
      },
      {
        "centroid": [
          897,
          513.2105263157895
        ],
        "area": [
          [
            [
              978,
              444
            ],
            [
              960,
              529
            ],
            [
              959,
              530
            ],
            [
              947,
              529
            ],
            [
              941,
              530
            ],
            [
              941,
              533
            ],
            [
              929,
              590
            ],
            [
              921,
              589
            ],
            [
              883,
              581
            ],
            [
              872,
              578
            ],
            [
              850,
              574
            ],
            [
              766,
              556
            ],
            [
              767,
              550
            ],
            [
              781,
              499
            ],
            [
              807,
              407
            ],
            [
              810,
              407
            ],
            [
              975,
              440
            ],
            [
              978,
              441
            ],
            [
              978,
              444
            ]
          ]
        ]
      },
      {
        "centroid": [
          902.84,
          539.84
        ],
        "area": [
          [
            [
              996,
              480
            ],
            [
              959,
              564
            ],
            [
              951,
              562
            ],
            [
              947,
              560
            ],
            [
              941,
              561
            ],
            [
              940,
              562
            ],
            [
              940,
              564
            ],
            [
              939,
              565
            ],
            [
              918,
              615
            ],
            [
              916,
              618
            ],
            [
              914,
              618
            ],
            [
              873,
              602
            ],
            [
              863,
              597
            ],
            [
              855,
              595
            ],
            [
              801,
              574
            ],
            [
              789,
              568
            ],
            [
              783,
              567
            ],
            [
              761,
              558
            ],
            [
              832,
              418
            ],
            [
              835,
              418
            ],
            [
              841,
              420
            ],
            [
              990,
              475
            ],
            [
              995,
              477
            ],
            [
              996,
              478
            ],
            [
              996,
              480
            ]
          ]
        ]
      },
      {
        "centroid": [
          897.5909090909091,
          562.0909090909091
        ],
        "area": [
          [
            [
              1007,
              518
            ],
            [
              953,
              596
            ],
            [
              951,
              597
            ],
            [
              941,
              592
            ],
            [
              934,
              592
            ],
            [
              932,
              594
            ],
            [
              930,
              598
            ],
            [
              921,
              610
            ],
            [
              898,
              644
            ],
            [
              893,
              642
            ],
            [
              876,
              632
            ],
            [
              848,
              614
            ],
            [
              843,
              612
            ],
            [
              757,
              560
            ],
            [
              758,
              557
            ],
            [
              761,
              553
            ],
            [
              841,
              450
            ],
            [
              852,
              436
            ],
            [
              856,
              432
            ],
            [
              981,
              502
            ],
            [
              1007,
              517
            ],
            [
              1007,
              518
            ]
          ]
        ]
      },
      {
        "centroid": [
          860.609756097561,
          565.7317073170732
        ],
        "area": [
          [
            [
              1010,
              559
            ],
            [
              938,
              629
            ],
            [
              931,
              625
            ],
            [
              931,
              624
            ],
            [
              930,
              624
            ],
            [
              929,
              622
            ],
            [
              919,
              622
            ],
            [
              880,
              661
            ],
            [
              874,
              666
            ],
            [
              872,
              666
            ],
            [
              858,
              653
            ],
            [
              841,
              639
            ],
            [
              830,
              628
            ],
            [
              826,
              626
            ],
            [
              819,
              620
            ],
            [
              812,
              613
            ],
            [
              803,
              606
            ],
            [
              797,
              600
            ],
            [
              788,
              593
            ],
            [
              752,
              561
            ],
            [
              752,
              560
            ],
            [
              760,
              552
            ],
            [
              771,
              543
            ],
            [
              779,
              535
            ],
            [
              781,
              534
            ],
            [
              788,
              527
            ],
            [
              799,
              518
            ],
            [
              805,
              512
            ],
            [
              807,
              511
            ],
            [
              831,
              489
            ],
            [
              833,
              488
            ],
            [
              841,
              480
            ],
            [
              851,
              472
            ],
            [
              875,
              450
            ],
            [
              879,
              452
            ],
            [
              889,
              460
            ],
            [
              946,
              507
            ],
            [
              969,
              525
            ],
            [
              973,
              529
            ],
            [
              1006,
              555
            ],
            [
              1010,
              559
            ]
          ]
        ]
      },
      {
        "centroid": [
          903.439024390244,
          603.7073170731708
        ],
        "area": [
          [
            [
              1004,
              600
            ],
            [
              940,
              643
            ],
            [
              930,
              649
            ],
            [
              919,
              657
            ],
            [
              917,
              658
            ],
            [
              914,
              656
            ],
            [
              913,
              654
            ],
            [
              912,
              654
            ],
            [
              911,
              652
            ],
            [
              909,
              651
            ],
            [
              909,
              650
            ],
            [
              900,
              648
            ],
            [
              896,
              651
            ],
            [
              895,
              651
            ],
            [
              892,
              654
            ],
            [
              889,
              655
            ],
            [
              880,
              662
            ],
            [
              873,
              666
            ],
            [
              868,
              670
            ],
            [
              845,
              685
            ],
            [
              816,
              649
            ],
            [
              811,
              641
            ],
            [
              803,
              633
            ],
            [
              764,
              584
            ],
            [
              746,
              561
            ],
            [
              746,
              560
            ],
            [
              889,
              472
            ],
            [
              892,
              471
            ],
            [
              911,
              492
            ],
            [
              923,
              507
            ],
            [
              929,
              513
            ],
            [
              942,
              529
            ],
            [
              948,
              535
            ],
            [
              955,
              544
            ],
            [
              961,
              550
            ],
            [
              968,
              559
            ],
            [
              974,
              565
            ],
            [
              975,
              567
            ],
            [
              981,
              573
            ],
            [
              987,
              581
            ],
            [
              1004,
              600
            ]
          ]
        ]
      },
      {
        "centroid": [
          867.8125,
          650.3125
        ],
        "area": [
          [
            [
              989,
              640
            ],
            [
              984,
              643
            ],
            [
              947,
              659
            ],
            [
              905,
              676
            ],
            [
              903,
              678
            ],
            [
              891,
              683
            ],
            [
              890,
              683
            ],
            [
              889,
              682
            ],
            [
              889,
              680
            ],
            [
              888,
              680
            ],
            [
              887,
              678
            ],
            [
              885,
              677
            ],
            [
              885,
              676
            ],
            [
              883,
              674
            ],
            [
              883,
              672
            ],
            [
              871,
              672
            ],
            [
              853,
              681
            ],
            [
              851,
              681
            ],
            [
              848,
              683
            ],
            [
              845,
              683
            ],
            [
              845,
              684
            ],
            [
              839,
              687
            ],
            [
              813,
              698
            ],
            [
              798,
              670
            ],
            [
              787,
              647
            ],
            [
              785,
              645
            ],
            [
              741,
              560
            ],
            [
              743,
              558
            ],
            [
              760,
              551
            ],
            [
              900,
              495
            ],
            [
              904,
              494
            ],
            [
              989,
              640
            ]
          ]
        ]
      },
      {
        "centroid": [
          833.9111111111112,
          672.8666666666667
        ],
        "area": [
          [
            [
              966,
              678
            ],
            [
              950,
              681
            ],
            [
              858,
              704
            ],
            [
              857,
              700
            ],
            [
              855,
              698
            ],
            [
              855,
              696
            ],
            [
              854,
              696
            ],
            [
              853,
              690
            ],
            [
              843,
              689
            ],
            [
              839,
              691
            ],
            [
              835,
              691
            ],
            [
              831,
              693
            ],
            [
              823,
              694
            ],
            [
              819,
              696
            ],
            [
              815,
              696
            ],
            [
              814,
              697
            ],
            [
              811,
              697
            ],
            [
              810,
              698
            ],
            [
              807,
              698
            ],
            [
              806,
              699
            ],
            [
              803,
              699
            ],
            [
              802,
              700
            ],
            [
              799,
              700
            ],
            [
              798,
              701
            ],
            [
              795,
              701
            ],
            [
              794,
              702
            ],
            [
              791,
              702
            ],
            [
              790,
              703
            ],
            [
              787,
              703
            ],
            [
              786,
              704
            ],
            [
              779,
              705
            ],
            [
              778,
              704
            ],
            [
              767,
              666
            ],
            [
              764,
              652
            ],
            [
              762,
              648
            ],
            [
              757,
              632
            ],
            [
              736,
              558
            ],
            [
              745,
              555
            ],
            [
              907,
              519
            ],
            [
              911,
              519
            ],
            [
              944,
              613
            ],
            [
              946,
              621
            ],
            [
              952,
              636
            ],
            [
              966,
              676
            ],
            [
              966,
              678
            ]
          ]
        ]
      },
      {
        "centroid": [
          803.5087719298245,
          656.1228070175439
        ],
        "area": [
          [
            [
              935,
              711
            ],
            [
              899,
              712
            ],
            [
              890,
              714
            ],
            [
              877,
              714
            ],
            [
              877,
              715
            ],
            [
              841,
              716
            ],
            [
              841,
              717
            ],
            [
              834,
              718
            ],
            [
              822,
              718
            ],
            [
              820,
              712
            ],
            [
              820,
              706
            ],
            [
              819,
              704
            ],
            [
              811,
              702
            ],
            [
              805,
              702
            ],
            [
              803,
              703
            ],
            [
              791,
              703
            ],
            [
              789,
              704
            ],
            [
              777,
              704
            ],
            [
              776,
              705
            ],
            [
              765,
              705
            ],
            [
              763,
              706
            ],
            [
              753,
              706
            ],
            [
              751,
              707
            ],
            [
              744,
              707
            ],
            [
              743,
              702
            ],
            [
              743,
              691
            ],
            [
              742,
              690
            ],
            [
              742,
              681
            ],
            [
              741,
              679
            ],
            [
              741,
              667
            ],
            [
              740,
              666
            ],
            [
              740,
              651
            ],
            [
              739,
              650
            ],
            [
              738,
              641
            ],
            [
              738,
              631
            ],
            [
              737,
              629
            ],
            [
              736,
              605
            ],
            [
              735,
              604
            ],
            [
              735,
              591
            ],
            [
              734,
              591
            ],
            [
              734,
              579
            ],
            [
              733,
              578
            ],
            [
              732,
              555
            ],
            [
              737,
              554
            ],
            [
              781,
              552
            ],
            [
              804,
              550
            ],
            [
              815,
              550
            ],
            [
              839,
              548
            ],
            [
              849,
              548
            ],
            [
              872,
              546
            ],
            [
              912,
              544
            ],
            [
              913,
              545
            ],
            [
              925,
              632
            ],
            [
              927,
              653
            ],
            [
              930,
              669
            ],
            [
              935,
              705
            ],
            [
              935,
              711
            ]
          ]
        ]
      },
      {
        "centroid": [
          829.1866666666666,
          627.1333333333333
        ],
        "area": [
          [
            [
              909,
              571
            ],
            [
              908,
              571
            ],
            [
              908,
              589
            ],
            [
              907,
              589
            ],
            [
              907,
              606
            ],
            [
              906,
              609
            ],
            [
              906,
              621
            ],
            [
              905,
              623
            ],
            [
              905,
              637
            ],
            [
              904,
              640
            ],
            [
              904,
              651
            ],
            [
              903,
              653
            ],
            [
              903,
              666
            ],
            [
              901,
              680
            ],
            [
              900,
              714
            ],
            [
              899,
              715
            ],
            [
              899,
              726
            ],
            [
              898,
              727
            ],
            [
              898,
              736
            ],
            [
              897,
              738
            ],
            [
              875,
              735
            ],
            [
              833,
              732
            ],
            [
              825,
              730
            ],
            [
              817,
              730
            ],
            [
              785,
              727
            ],
            [
              784,
              726
            ],
            [
              783,
              721
            ],
            [
              784,
              711
            ],
            [
              783,
              709
            ],
            [
              774,
              709
            ],
            [
              773,
              708
            ],
            [
              762,
              708
            ],
            [
              759,
              707
            ],
            [
              750,
              707
            ],
            [
              747,
              706
            ],
            [
              740,
              706
            ],
            [
              737,
              705
            ],
            [
              728,
              705
            ],
            [
              725,
              704
            ],
            [
              709,
              703
            ],
            [
              723,
              587
            ],
            [
              725,
              577
            ],
            [
              727,
              554
            ],
            [
              728,
              552
            ],
            [
              738,
              552
            ],
            [
              747,
              553
            ],
            [
              749,
              554
            ],
            [
              766,
              555
            ],
            [
              767,
              556
            ],
            [
              777,
              556
            ],
            [
              777,
              557
            ],
            [
              788,
              557
            ],
            [
              789,
              558
            ],
            [
              798,
              558
            ],
            [
              807,
              559
            ],
            [
              809,
              560
            ],
            [
              817,
              560
            ],
            [
              817,
              561
            ],
            [
              828,
              561
            ],
            [
              837,
              562
            ],
            [
              837,
              563
            ],
            [
              847,
              563
            ],
            [
              849,
              564
            ],
            [
              858,
              564
            ],
            [
              859,
              565
            ],
            [
              868,
              565
            ],
            [
              869,
              566
            ],
            [
              878,
              566
            ],
            [
              879,
              567
            ],
            [
              888,
              567
            ],
            [
              889,
              568
            ],
            [
              898,
              568
            ],
            [
              899,
              569
            ],
            [
              908,
              569
            ],
            [
              909,
              571
            ]
          ]
        ]
      },
      {
        "centroid": [
          773.4848484848485,
          681.6363636363636
        ],
        "area": [
          [
            [
              888,
              591
            ],
            [
              883,
              607
            ],
            [
              878,
              627
            ],
            [
              861,
              738
            ],
            [
              855,
              758
            ],
            [
              852,
              758
            ],
            [
              837,
              753
            ],
            [
              807,
              746
            ],
            [
              763,
              733
            ],
            [
              759,
              733
            ],
            [
              748,
              730
            ],
            [
              745,
              728
            ],
            [
              745,
              723
            ],
            [
              747,
              712
            ],
            [
              743,
              710
            ],
            [
              740,
              710
            ],
            [
              739,
              709
            ],
            [
              736,
              709
            ],
            [
              735,
              708
            ],
            [
              732,
              708
            ],
            [
              731,
              707
            ],
            [
              724,
              706
            ],
            [
              723,
              705
            ],
            [
              701,
              700
            ],
            [
              678,
              694
            ],
            [
              677,
              693
            ],
            [
              678,
              687
            ],
            [
              724,
              549
            ],
            [
              725,
              548
            ],
            [
              735,
              550
            ],
            [
              861,
              583
            ],
            [
              887,
              590
            ],
            [
              888,
              591
            ]
          ]
        ]
      },
      {
        "centroid": [
          756.5094339622641,
          682.0754716981132
        ],
        "area": [
          [
            [
              844,
              598
            ],
            [
              820,
              645
            ],
            [
              820,
              655
            ],
            [
              819,
              655
            ],
            [
              819,
              666
            ],
            [
              818,
              667
            ],
            [
              818,
              679
            ],
            [
              817,
              679
            ],
            [
              817,
              690
            ],
            [
              816,
              691
            ],
            [
              816,
              701
            ],
            [
              815,
              701
            ],
            [
              815,
              713
            ],
            [
              814,
              713
            ],
            [
              814,
              725
            ],
            [
              813,
              725
            ],
            [
              813,
              736
            ],
            [
              812,
              737
            ],
            [
              812,
              749
            ],
            [
              811,
              749
            ],
            [
              811,
              761
            ],
            [
              810,
              770
            ],
            [
              809,
              772
            ],
            [
              752,
              746
            ],
            [
              733,
              736
            ],
            [
              707,
              725
            ],
            [
              706,
              723
            ],
            [
              707,
              719
            ],
            [
              708,
              717
            ],
            [
              709,
              717
            ],
            [
              710,
              707
            ],
            [
              709,
              706
            ],
            [
              707,
              706
            ],
            [
              707,
              705
            ],
            [
              705,
              705
            ],
            [
              698,
              702
            ],
            [
              691,
              698
            ],
            [
              689,
              698
            ],
            [
              673,
              690
            ],
            [
              669,
              689
            ],
            [
              651,
              681
            ],
            [
              646,
              678
            ],
            [
              661,
              651
            ],
            [
              671,
              635
            ],
            [
              677,
              623
            ],
            [
              691,
              600
            ],
            [
              698,
              586
            ],
            [
              709,
              568
            ],
            [
              722,
              544
            ],
            [
              738,
              550
            ],
            [
              790,
              573
            ],
            [
              844,
              597
            ],
            [
              844,
              598
            ]
          ]
        ]
      },
      {
        "centroid": [
          713.7307692307693,
          671.5384615384615
        ],
        "area": [
          [
            [
              799,
              602
            ],
            [
              773,
              635
            ],
            [
              767,
              641
            ],
            [
              763,
              647
            ],
            [
              762,
              647
            ],
            [
              762,
              658
            ],
            [
              761,
              661
            ],
            [
              761,
              719
            ],
            [
              760,
              719
            ],
            [
              760,
              776
            ],
            [
              756,
              774
            ],
            [
              673,
              717
            ],
            [
              669,
              713
            ],
            [
              669,
              712
            ],
            [
              673,
              707
            ],
            [
              674,
              707
            ],
            [
              674,
              696
            ],
            [
              638,
              672
            ],
            [
              635,
              669
            ],
            [
              634,
              669
            ],
            [
              629,
              665
            ],
            [
              626,
              664
            ],
            [
              620,
              659
            ],
            [
              721,
              539
            ],
            [
              799,
              590
            ],
            [
              799,
              602
            ]
          ]
        ]
      },
      {
        "centroid": [
          678.6216216216217,
          635.5945945945946
        ],
        "area": [
          [
            [
              752,
              565
            ],
            [
              749,
              573
            ],
            [
              749,
              604
            ],
            [
              742,
              611
            ],
            [
              733,
              617
            ],
            [
              729,
              622
            ],
            [
              703,
              644
            ],
            [
              705,
              692
            ],
            [
              707,
              718
            ],
            [
              707,
              731
            ],
            [
              709,
              755
            ],
            [
              709,
              771
            ],
            [
              639,
              701
            ],
            [
              637,
              700
            ],
            [
              635,
              697
            ],
            [
              644,
              689
            ],
            [
              644,
              681
            ],
            [
              638,
              675
            ],
            [
              636,
              674
            ],
            [
              635,
              672
            ],
            [
              634,
              672
            ],
            [
              608,
              646
            ],
            [
              606,
              645
            ],
            [
              599,
              638
            ],
            [
              599,
              636
            ],
            [
              603,
              632
            ],
            [
              620,
              618
            ],
            [
              626,
              614
            ],
            [
              632,
              608
            ],
            [
              691,
              559
            ],
            [
              698,
              554
            ],
            [
              703,
              549
            ],
            [
              720,
              535
            ],
            [
              721,
              535
            ],
            [
              743,
              555
            ],
            [
              752,
              564
            ],
            [
              752,
              565
            ]
          ]
        ]
      },
      {
        "centroid": [
          668.375,
          607
        ],
        "area": [
          [
            [
              725,
              545
            ],
            [
              717,
              550
            ],
            [
              713,
              551
            ],
            [
              706,
              557
            ],
            [
              698,
              561
            ],
            [
              697,
              563
            ],
            [
              699,
              599
            ],
            [
              691,
              605
            ],
            [
              680,
              609
            ],
            [
              676,
              613
            ],
            [
              652,
              628
            ],
            [
              649,
              629
            ],
            [
              647,
              631
            ],
            [
              648,
              647
            ],
            [
              661,
              757
            ],
            [
              607,
              679
            ],
            [
              606,
              675
            ],
            [
              615,
              670
            ],
            [
              618,
              663
            ],
            [
              583,
              614
            ],
            [
              582,
              611
            ],
            [
              721,
              530
            ],
            [
              725,
              536
            ],
            [
              725,
              545
            ]
          ]
        ]
      },
      {
        "centroid": [
          604.4,
          618.1714285714286
        ],
        "area": [
          [
            [
              652,
              587
            ],
            [
              640,
              592
            ],
            [
              631,
              592
            ],
            [
              630,
              594
            ],
            [
              625,
              596
            ],
            [
              617,
              601
            ],
            [
              615,
              601
            ],
            [
              614,
              602
            ],
            [
              612,
              602
            ],
            [
              604,
              605
            ],
            [
              602,
              607
            ],
            [
              600,
              607
            ],
            [
              596,
              609
            ],
            [
              594,
              611
            ],
            [
              612,
              719
            ],
            [
              614,
              727
            ],
            [
              582,
              654
            ],
            [
              582,
              651
            ],
            [
              583,
              651
            ],
            [
              585,
              649
            ],
            [
              587,
              649
            ],
            [
              587,
              648
            ],
            [
              589,
              647
            ],
            [
              591,
              647
            ],
            [
              595,
              639
            ],
            [
              594,
              635
            ],
            [
              589,
              626
            ],
            [
              584,
              615
            ],
            [
              583,
              611
            ],
            [
              574,
              593
            ],
            [
              571,
              586
            ],
            [
              571,
              584
            ],
            [
              648,
              555
            ],
            [
              649,
              557
            ],
            [
              652,
              587
            ]
          ]
        ]
      },
      {
        "centroid": [
          589.1666666666666,
          566.4166666666666
        ],
        "area": [
          [
            [
              608,
              567
            ],
            [
              607,
              568
            ],
            [
              596,
              571
            ],
            [
              583,
              570
            ],
            [
              578,
              572
            ],
            [
              575,
              575
            ],
            [
              570,
              576
            ],
            [
              569,
              575
            ],
            [
              565,
              557
            ],
            [
              605,
              548
            ],
            [
              606,
              551
            ],
            [
              608,
              567
            ]
          ]
        ]
      }
    ]
  },
  {
    "houseId": "h1",
    "steps": [
      {
        "centroid": [
          921.8333333333334,
          546.8333333333334
        ],
        "area": [
          [
            [
              1013,
              476
            ],
            [
              1011,
              481
            ],
            [
              969,
              546
            ],
            [
              966,
              548
            ],
            [
              954,
              546
            ],
            [
              931,
              582
            ],
            [
              929,
              582
            ],
            [
              926,
              580
            ],
            [
              917,
              579
            ],
            [
              915,
              582
            ],
            [
              914,
              582
            ],
            [
              912,
              587
            ],
            [
              908,
              592
            ],
            [
              886,
              626
            ],
            [
              882,
              630
            ],
            [
              807,
              588
            ],
            [
              804,
              585
            ],
            [
              804,
              583
            ],
            [
              809,
              575
            ],
            [
              915,
              425
            ],
            [
              934,
              434
            ],
            [
              992,
              464
            ],
            [
              1013,
              475
            ],
            [
              1013,
              476
            ]
          ]
        ]
      },
      {
        "centroid": [
          906,
          570.7741935483871
        ],
        "area": [
          [
            [
              1025,
              516
            ],
            [
              1014,
              529
            ],
            [
              966,
              579
            ],
            [
              962,
              582
            ],
            [
              959,
              580
            ],
            [
              959,
              579
            ],
            [
              949,
              579
            ],
            [
              919,
              610
            ],
            [
              919,
              609
            ],
            [
              918,
              609
            ],
            [
              916,
              607
            ],
            [
              916,
              606
            ],
            [
              908,
              603
            ],
            [
              900,
              612
            ],
            [
              869,
              643
            ],
            [
              866,
              647
            ],
            [
              862,
              649
            ],
            [
              861,
              649
            ],
            [
              857,
              646
            ],
            [
              792,
              593
            ],
            [
              796,
              588
            ],
            [
              808,
              576
            ],
            [
              809,
              576
            ],
            [
              819,
              565
            ],
            [
              823,
              562
            ],
            [
              923,
              463
            ],
            [
              926,
              461
            ],
            [
              936,
              451
            ],
            [
              937,
              451
            ],
            [
              947,
              458
            ],
            [
              1025,
              516
            ]
          ]
        ]
      },
      {
        "centroid": [
          917.9428571428572,
          590.4
        ],
        "area": [
          [
            [
              1028,
              559
            ],
            [
              1024,
              563
            ],
            [
              1016,
              569
            ],
            [
              957,
              612
            ],
            [
              953,
              614
            ],
            [
              951,
              614
            ],
            [
              950,
              612
            ],
            [
              940,
              609
            ],
            [
              933,
              614
            ],
            [
              932,
              614
            ],
            [
              929,
              617
            ],
            [
              903,
              636
            ],
            [
              902,
              635
            ],
            [
              902,
              633
            ],
            [
              901,
              633
            ],
            [
              899,
              631
            ],
            [
              899,
              630
            ],
            [
              889,
              630
            ],
            [
              869,
              645
            ],
            [
              840,
              665
            ],
            [
              837,
              665
            ],
            [
              834,
              662
            ],
            [
              780,
              600
            ],
            [
              780,
              599
            ],
            [
              782,
              597
            ],
            [
              797,
              586
            ],
            [
              872,
              535
            ],
            [
              935,
              491
            ],
            [
              952,
              480
            ],
            [
              953,
              480
            ],
            [
              959,
              486
            ],
            [
              960,
              488
            ],
            [
              1014,
              543
            ],
            [
              1028,
              558
            ],
            [
              1028,
              559
            ]
          ]
        ]
      },
      {
        "centroid": [
          884.6666666666666,
          612.7878787878788
        ],
        "area": [
          [
            [
              1023,
              602
            ],
            [
              1017,
              606
            ],
            [
              940,
              644
            ],
            [
              934,
              645
            ],
            [
              932,
              643
            ],
            [
              931,
              640
            ],
            [
              921,
              639
            ],
            [
              882,
              659
            ],
            [
              881,
              659
            ],
            [
              879,
              654
            ],
            [
              877,
              652
            ],
            [
              869,
              651
            ],
            [
              857,
              657
            ],
            [
              855,
              657
            ],
            [
              815,
              677
            ],
            [
              811,
              678
            ],
            [
              810,
              678
            ],
            [
              808,
              675
            ],
            [
              766,
              603
            ],
            [
              766,
              602
            ],
            [
              768,
              602
            ],
            [
              803,
              585
            ],
            [
              809,
              583
            ],
            [
              831,
              572
            ],
            [
              839,
              569
            ],
            [
              861,
              558
            ],
            [
              871,
              554
            ],
            [
              904,
              538
            ],
            [
              961,
              512
            ],
            [
              963,
              512
            ],
            [
              964,
              513
            ],
            [
              1023,
              601
            ],
            [
              1023,
              602
            ]
          ]
        ]
      },
      {
        "centroid": [
          860.8571428571429,
          654.6904761904761
        ],
        "area": [
          [
            [
              1009,
              645
            ],
            [
              999,
              649
            ],
            [
              917,
              673
            ],
            [
              911,
              674
            ],
            [
              909,
              672
            ],
            [
              909,
              670
            ],
            [
              907,
              667
            ],
            [
              895,
              666
            ],
            [
              894,
              667
            ],
            [
              889,
              668
            ],
            [
              885,
              670
            ],
            [
              879,
              671
            ],
            [
              878,
              672
            ],
            [
              873,
              673
            ],
            [
              872,
              674
            ],
            [
              869,
              674
            ],
            [
              868,
              675
            ],
            [
              855,
              679
            ],
            [
              852,
              670
            ],
            [
              841,
              669
            ],
            [
              837,
              671
            ],
            [
              831,
              672
            ],
            [
              830,
              673
            ],
            [
              827,
              673
            ],
            [
              826,
              674
            ],
            [
              817,
              676
            ],
            [
              816,
              677
            ],
            [
              813,
              677
            ],
            [
              809,
              679
            ],
            [
              805,
              679
            ],
            [
              805,
              680
            ],
            [
              788,
              685
            ],
            [
              780,
              686
            ],
            [
              752,
              604
            ],
            [
              755,
              604
            ],
            [
              766,
              600
            ],
            [
              773,
              599
            ],
            [
              831,
              582
            ],
            [
              837,
              581
            ],
            [
              966,
              545
            ],
            [
              972,
              557
            ],
            [
              1009,
              645
            ]
          ]
        ]
      },
      {
        "centroid": [
          839.9148936170212,
          665.8936170212766
        ],
        "area": [
          [
            [
              985,
              686
            ],
            [
              975,
              688
            ],
            [
              892,
              698
            ],
            [
              881,
              698
            ],
            [
              879,
              690
            ],
            [
              871,
              688
            ],
            [
              859,
              689
            ],
            [
              857,
              690
            ],
            [
              851,
              690
            ],
            [
              849,
              691
            ],
            [
              843,
              691
            ],
            [
              841,
              692
            ],
            [
              835,
              692
            ],
            [
              833,
              693
            ],
            [
              827,
              693
            ],
            [
              825,
              694
            ],
            [
              824,
              693
            ],
            [
              824,
              686
            ],
            [
              823,
              684
            ],
            [
              815,
              682
            ],
            [
              811,
              683
            ],
            [
              803,
              683
            ],
            [
              802,
              684
            ],
            [
              795,
              684
            ],
            [
              793,
              685
            ],
            [
              787,
              685
            ],
            [
              785,
              686
            ],
            [
              777,
              686
            ],
            [
              776,
              687
            ],
            [
              769,
              687
            ],
            [
              767,
              688
            ],
            [
              761,
              688
            ],
            [
              759,
              689
            ],
            [
              749,
              689
            ],
            [
              738,
              608
            ],
            [
              738,
              604
            ],
            [
              742,
              603
            ],
            [
              823,
              595
            ],
            [
              832,
              593
            ],
            [
              877,
              589
            ],
            [
              887,
              587
            ],
            [
              903,
              586
            ],
            [
              914,
              584
            ],
            [
              963,
              579
            ],
            [
              966,
              590
            ],
            [
              985,
              681
            ],
            [
              985,
              686
            ]
          ]
        ]
      },
      {
        "centroid": [
          824.3125,
          655.4791666666666
        ],
        "area": [
          [
            [
              953,
              723
            ],
            [
              897,
              721
            ],
            [
              867,
              719
            ],
            [
              854,
              719
            ],
            [
              847,
              718
            ],
            [
              846,
              717
            ],
            [
              846,
              707
            ],
            [
              845,
              706
            ],
            [
              839,
              706
            ],
            [
              837,
              705
            ],
            [
              812,
              705
            ],
            [
              809,
              704
            ],
            [
              791,
              704
            ],
            [
              792,
              693
            ],
            [
              791,
              691
            ],
            [
              781,
              691
            ],
            [
              779,
              690
            ],
            [
              761,
              690
            ],
            [
              757,
              689
            ],
            [
              741,
              689
            ],
            [
              737,
              688
            ],
            [
              723,
              688
            ],
            [
              718,
              687
            ],
            [
              722,
              623
            ],
            [
              724,
              602
            ],
            [
              742,
              602
            ],
            [
              745,
              603
            ],
            [
              759,
              603
            ],
            [
              763,
              604
            ],
            [
              780,
              604
            ],
            [
              781,
              605
            ],
            [
              800,
              605
            ],
            [
              801,
              606
            ],
            [
              820,
              606
            ],
            [
              821,
              607
            ],
            [
              841,
              607
            ],
            [
              843,
              608
            ],
            [
              859,
              608
            ],
            [
              861,
              609
            ],
            [
              885,
              609
            ],
            [
              887,
              610
            ],
            [
              901,
              610
            ],
            [
              903,
              611
            ],
            [
              926,
              611
            ],
            [
              929,
              612
            ],
            [
              945,
              612
            ],
            [
              953,
              613
            ],
            [
              953,
              723
            ]
          ]
        ]
      },
      {
        "centroid": [
          790.4054054054054,
          685.4864864864865
        ],
        "area": [
          [
            [
              936,
              645
            ],
            [
              914,
              753
            ],
            [
              913,
              754
            ],
            [
              905,
              753
            ],
            [
              810,
              732
            ],
            [
              808,
              731
            ],
            [
              807,
              719
            ],
            [
              805,
              718
            ],
            [
              792,
              716
            ],
            [
              781,
              713
            ],
            [
              773,
              712
            ],
            [
              767,
              710
            ],
            [
              759,
              709
            ],
            [
              757,
              708
            ],
            [
              757,
              695
            ],
            [
              754,
              695
            ],
            [
              753,
              694
            ],
            [
              750,
              694
            ],
            [
              749,
              693
            ],
            [
              745,
              693
            ],
            [
              739,
              691
            ],
            [
              736,
              691
            ],
            [
              721,
              687
            ],
            [
              718,
              687
            ],
            [
              700,
              683
            ],
            [
              689,
              680
            ],
            [
              688,
              679
            ],
            [
              688,
              677
            ],
            [
              699,
              637
            ],
            [
              711,
              596
            ],
            [
              810,
              617
            ],
            [
              826,
              621
            ],
            [
              866,
              629
            ],
            [
              868,
              630
            ],
            [
              880,
              632
            ],
            [
              935,
              644
            ],
            [
              936,
              645
            ]
          ]
        ]
      },
      {
        "centroid": [
          763.34375,
          687.875
        ],
        "area": [
          [
            [
              912,
              674
            ],
            [
              867,
              778
            ],
            [
              857,
              775
            ],
            [
              775,
              742
            ],
            [
              767,
              738
            ],
            [
              768,
              733
            ],
            [
              767,
              725
            ],
            [
              759,
              721
            ],
            [
              754,
              720
            ],
            [
              751,
              718
            ],
            [
              749,
              718
            ],
            [
              721,
              707
            ],
            [
              721,
              705
            ],
            [
              723,
              703
            ],
            [
              721,
              693
            ],
            [
              713,
              689
            ],
            [
              711,
              689
            ],
            [
              706,
              687
            ],
            [
              701,
              684
            ],
            [
              699,
              684
            ],
            [
              669,
              672
            ],
            [
              661,
              668
            ],
            [
              660,
              666
            ],
            [
              688,
              610
            ],
            [
              699,
              589
            ],
            [
              700,
              589
            ],
            [
              779,
              621
            ],
            [
              804,
              630
            ],
            [
              896,
              667
            ],
            [
              905,
              670
            ],
            [
              912,
              673
            ],
            [
              912,
              674
            ]
          ]
        ]
      },
      {
        "centroid": [
          718.6060606060606,
          693
        ],
        "area": [
          [
            [
              882,
              698
            ],
            [
              816,
              794
            ],
            [
              811,
              792
            ],
            [
              731,
              742
            ],
            [
              725,
              737
            ],
            [
              727,
              734
            ],
            [
              728,
              725
            ],
            [
              725,
              723
            ],
            [
              725,
              722
            ],
            [
              715,
              717
            ],
            [
              710,
              713
            ],
            [
              707,
              712
            ],
            [
              698,
              706
            ],
            [
              697,
              706
            ],
            [
              687,
              699
            ],
            [
              689,
              695
            ],
            [
              690,
              686
            ],
            [
              689,
              685
            ],
            [
              688,
              685
            ],
            [
              680,
              679
            ],
            [
              677,
              678
            ],
            [
              662,
              668
            ],
            [
              661,
              668
            ],
            [
              656,
              664
            ],
            [
              653,
              663
            ],
            [
              645,
              658
            ],
            [
              635,
              651
            ],
            [
              635,
              649
            ],
            [
              689,
              580
            ],
            [
              783,
              637
            ],
            [
              790,
              642
            ],
            [
              826,
              663
            ],
            [
              882,
              698
            ]
          ]
        ]
      },
      {
        "centroid": [
          681.4318181818181,
          686.2272727272727
        ],
        "area": [
          [
            [
              847,
              717
            ],
            [
              763,
              800
            ],
            [
              761,
              800
            ],
            [
              753,
              794
            ],
            [
              743,
              784
            ],
            [
              740,
              782
            ],
            [
              731,
              773
            ],
            [
              728,
              771
            ],
            [
              719,
              762
            ],
            [
              716,
              760
            ],
            [
              707,
              751
            ],
            [
              690,
              736
            ],
            [
              685,
              730
            ],
            [
              685,
              729
            ],
            [
              689,
              725
            ],
            [
              689,
              717
            ],
            [
              665,
              696
            ],
            [
              656,
              687
            ],
            [
              655,
              687
            ],
            [
              655,
              685
            ],
            [
              656,
              685
            ],
            [
              656,
              684
            ],
            [
              658,
              682
            ],
            [
              659,
              682
            ],
            [
              661,
              674
            ],
            [
              656,
              670
            ],
            [
              644,
              658
            ],
            [
              643,
              658
            ],
            [
              635,
              650
            ],
            [
              633,
              649
            ],
            [
              620,
              636
            ],
            [
              619,
              636
            ],
            [
              614,
              630
            ],
            [
              614,
              629
            ],
            [
              624,
              620
            ],
            [
              626,
              619
            ],
            [
              633,
              612
            ],
            [
              635,
              611
            ],
            [
              652,
              595
            ],
            [
              662,
              587
            ],
            [
              680,
              570
            ],
            [
              681,
              570
            ],
            [
              698,
              584
            ],
            [
              847,
              717
            ]
          ]
        ]
      },
      {
        "centroid": [
          677.4166666666666,
          685.25
        ],
        "area": [
          [
            [
              808,
              731
            ],
            [
              708,
              798
            ],
            [
              701,
              791
            ],
            [
              689,
              775
            ],
            [
              652,
              725
            ],
            [
              646,
              715
            ],
            [
              650,
              713
            ],
            [
              652,
              703
            ],
            [
              649,
              699
            ],
            [
              649,
              698
            ],
            [
              646,
              695
            ],
            [
              626,
              668
            ],
            [
              626,
              667
            ],
            [
              632,
              664
            ],
            [
              634,
              656
            ],
            [
              631,
              653
            ],
            [
              600,
              611
            ],
            [
              598,
              606
            ],
            [
              675,
              558
            ],
            [
              680,
              565
            ],
            [
              685,
              570
            ],
            [
              805,
              725
            ],
            [
              808,
              729
            ],
            [
              808,
              731
            ]
          ]
        ]
      },
      {
        "centroid": [
          641.3333333333334,
          676.875
        ],
        "area": [
          [
            [
              767,
              737
            ],
            [
              655,
              786
            ],
            [
              652,
              782
            ],
            [
              636,
              749
            ],
            [
              615,
              704
            ],
            [
              612,
              695
            ],
            [
              613,
              695
            ],
            [
              613,
              694
            ],
            [
              615,
              694
            ],
            [
              619,
              692
            ],
            [
              620,
              683
            ],
            [
              602,
              646
            ],
            [
              602,
              645
            ],
            [
              606,
              643
            ],
            [
              609,
              643
            ],
            [
              611,
              633
            ],
            [
              607,
              626
            ],
            [
              591,
              592
            ],
            [
              587,
              581
            ],
            [
              590,
              579
            ],
            [
              672,
              546
            ],
            [
              764,
              728
            ],
            [
              767,
              735
            ],
            [
              767,
              737
            ]
          ]
        ]
      },
      {
        "centroid": [
          604.5217391304348,
          630.1304347826087
        ],
        "area": [
          [
            [
              726,
              736
            ],
            [
              618,
              763
            ],
            [
              606,
              765
            ],
            [
              604,
              761
            ],
            [
              583,
              673
            ],
            [
              583,
              669
            ],
            [
              587,
              669
            ],
            [
              591,
              667
            ],
            [
              593,
              659
            ],
            [
              592,
              653
            ],
            [
              591,
              652
            ],
            [
              591,
              649
            ],
            [
              590,
              648
            ],
            [
              590,
              645
            ],
            [
              589,
              644
            ],
            [
              589,
              641
            ],
            [
              588,
              640
            ],
            [
              588,
              637
            ],
            [
              586,
              633
            ],
            [
              583,
              620
            ],
            [
              584,
              619
            ],
            [
              587,
              619
            ],
            [
              587,
              618
            ],
            [
              591,
              618
            ],
            [
              593,
              617
            ],
            [
              594,
              607
            ],
            [
              590,
              593
            ],
            [
              590,
              589
            ],
            [
              589,
              588
            ],
            [
              587,
              581
            ],
            [
              587,
              577
            ],
            [
              586,
              576
            ],
            [
              586,
              573
            ],
            [
              585,
              572
            ],
            [
              585,
              569
            ],
            [
              584,
              568
            ],
            [
              584,
              565
            ],
            [
              583,
              564
            ],
            [
              583,
              561
            ],
            [
              582,
              560
            ],
            [
              581,
              554
            ],
            [
              671,
              534
            ],
            [
              677,
              553
            ],
            [
              722,
              718
            ],
            [
              726,
              733
            ],
            [
              726,
              736
            ]
          ]
        ]
      },
      {
        "centroid": [
          610.8363636363637,
          651.2181818181818
        ],
        "area": [
          [
            [
              686,
              728
            ],
            [
              680,
              729
            ],
            [
              667,
              729
            ],
            [
              667,
              730
            ],
            [
              655,
              730
            ],
            [
              654,
              731
            ],
            [
              641,
              731
            ],
            [
              641,
              732
            ],
            [
              629,
              732
            ],
            [
              628,
              733
            ],
            [
              617,
              733
            ],
            [
              614,
              734
            ],
            [
              603,
              734
            ],
            [
              600,
              735
            ],
            [
              589,
              735
            ],
            [
              586,
              736
            ],
            [
              573,
              736
            ],
            [
              573,
              737
            ],
            [
              563,
              737
            ],
            [
              562,
              730
            ],
            [
              562,
              701
            ],
            [
              561,
              700
            ],
            [
              561,
              678
            ],
            [
              560,
              676
            ],
            [
              560,
              641
            ],
            [
              561,
              640
            ],
            [
              567,
              640
            ],
            [
              568,
              639
            ],
            [
              571,
              639
            ],
            [
              572,
              631
            ],
            [
              572,
              621
            ],
            [
              571,
              619
            ],
            [
              571,
              601
            ],
            [
              570,
              600
            ],
            [
              570,
              592
            ],
            [
              571,
              591
            ],
            [
              581,
              591
            ],
            [
              583,
              583
            ],
            [
              583,
              565
            ],
            [
              582,
              564
            ],
            [
              582,
              537
            ],
            [
              581,
              536
            ],
            [
              582,
              528
            ],
            [
              584,
              527
            ],
            [
              661,
              522
            ],
            [
              673,
              522
            ],
            [
              674,
              534
            ],
            [
              674,
              547
            ],
            [
              676,
              561
            ],
            [
              677,
              585
            ],
            [
              678,
              592
            ],
            [
              679,
              623
            ],
            [
              681,
              640
            ],
            [
              683,
              671
            ],
            [
              686,
              728
            ]
          ]
        ]
      },
      {
        "centroid": [
          621.1111111111111,
          571.3333333333334
        ],
        "area": [
          [
            [
              677,
              510
            ],
            [
              676,
              511
            ],
            [
              676,
              522
            ],
            [
              675,
              529
            ],
            [
              674,
              531
            ],
            [
              673,
              539
            ],
            [
              672,
              539
            ],
            [
              672,
              550
            ],
            [
              669,
              567
            ],
            [
              668,
              567
            ],
            [
              668,
              578
            ],
            [
              663,
              610
            ],
            [
              662,
              611
            ],
            [
              662,
              621
            ],
            [
              656,
              664
            ],
            [
              655,
              667
            ],
            [
              654,
              667
            ],
            [
              654,
              678
            ],
            [
              649,
              713
            ],
            [
              648,
              714
            ],
            [
              643,
              714
            ],
            [
              534,
              704
            ],
            [
              528,
              702
            ],
            [
              528,
              695
            ],
            [
              539,
              629
            ],
            [
              543,
              609
            ],
            [
              545,
              608
            ],
            [
              552,
              608
            ],
            [
              555,
              609
            ],
            [
              557,
              601
            ],
            [
              562,
              567
            ],
            [
              563,
              564
            ],
            [
              565,
              562
            ],
            [
              569,
              563
            ],
            [
              575,
              563
            ],
            [
              577,
              558
            ],
            [
              585,
              510
            ],
            [
              587,
              502
            ],
            [
              593,
              502
            ],
            [
              593,
              503
            ],
            [
              605,
              503
            ],
            [
              607,
              504
            ],
            [
              615,
              504
            ],
            [
              615,
              505
            ],
            [
              627,
              505
            ],
            [
              629,
              506
            ],
            [
              638,
              506
            ],
            [
              639,
              507
            ],
            [
              649,
              507
            ],
            [
              651,
              508
            ],
            [
              660,
              508
            ],
            [
              661,
              509
            ],
            [
              671,
              509
            ],
            [
              677,
              510
            ]
          ]
        ]
      },
      {
        "centroid": [
          608.2173913043479,
          563.8695652173913
        ],
        "area": [
          [
            [
              684,
              499
            ],
            [
              681,
              505
            ],
            [
              680,
              510
            ],
            [
              668,
              545
            ],
            [
              665,
              550
            ],
            [
              662,
              562
            ],
            [
              656,
              579
            ],
            [
              651,
              590
            ],
            [
              650,
              596
            ],
            [
              615,
              694
            ],
            [
              612,
              694
            ],
            [
              502,
              665
            ],
            [
              500,
              663
            ],
            [
              534,
              574
            ],
            [
              537,
              575
            ],
            [
              547,
              574
            ],
            [
              562,
              534
            ],
            [
              563,
              533
            ],
            [
              567,
              534
            ],
            [
              576,
              533
            ],
            [
              595,
              483
            ],
            [
              598,
              478
            ],
            [
              684,
              499
            ]
          ]
        ]
      },
      {
        "centroid": [
          602.5217391304348,
          538.5652173913044
        ],
        "area": [
          [
            [
              692,
              490
            ],
            [
              689,
              493
            ],
            [
              680,
              511
            ],
            [
              668,
              531
            ],
            [
              665,
              534
            ],
            [
              657,
              550
            ],
            [
              639,
              579
            ],
            [
              633,
              591
            ],
            [
              586,
              670
            ],
            [
              504,
              633
            ],
            [
              482,
              622
            ],
            [
              489,
              609
            ],
            [
              531,
              542
            ],
            [
              532,
              541
            ],
            [
              536,
              542
            ],
            [
              545,
              542
            ],
            [
              568,
              504
            ],
            [
              572,
              506
            ],
            [
              581,
              506
            ],
            [
              612,
              456
            ],
            [
              614,
              456
            ],
            [
              691,
              489
            ],
            [
              692,
              490
            ]
          ]
        ]
      },
      {
        "centroid": [
          592.3448275862069,
          517.3793103448276
        ],
        "area": [
          [
            [
              702,
              482
            ],
            [
              698,
              485
            ],
            [
              692,
              494
            ],
            [
              662,
              527
            ],
            [
              659,
              532
            ],
            [
              629,
              565
            ],
            [
              625,
              571
            ],
            [
              564,
              641
            ],
            [
              561,
              640
            ],
            [
              537,
              624
            ],
            [
              473,
              580
            ],
            [
              473,
              578
            ],
            [
              476,
              574
            ],
            [
              536,
              508
            ],
            [
              538,
              508
            ],
            [
              542,
              511
            ],
            [
              551,
              509
            ],
            [
              551,
              508
            ],
            [
              563,
              495
            ],
            [
              571,
              485
            ],
            [
              578,
              478
            ],
            [
              581,
              479
            ],
            [
              584,
              482
            ],
            [
              593,
              479
            ],
            [
              612,
              457
            ],
            [
              631,
              437
            ],
            [
              632,
              437
            ],
            [
              662,
              456
            ],
            [
              702,
              482
            ]
          ]
        ]
      },
      {
        "centroid": [
          601.6470588235294,
          501.5
        ],
        "area": [
          [
            [
              713,
              476
            ],
            [
              707,
              480
            ],
            [
              701,
              486
            ],
            [
              674,
              508
            ],
            [
              662,
              516
            ],
            [
              659,
              520
            ],
            [
              650,
              526
            ],
            [
              643,
              533
            ],
            [
              638,
              536
            ],
            [
              632,
              542
            ],
            [
              627,
              545
            ],
            [
              616,
              555
            ],
            [
              547,
              611
            ],
            [
              508,
              572
            ],
            [
              504,
              569
            ],
            [
              473,
              538
            ],
            [
              472,
              536
            ],
            [
              472,
              535
            ],
            [
              545,
              478
            ],
            [
              548,
              477
            ],
            [
              550,
              478
            ],
            [
              554,
              482
            ],
            [
              561,
              479
            ],
            [
              563,
              479
            ],
            [
              563,
              478
            ],
            [
              567,
              474
            ],
            [
              594,
              453
            ],
            [
              599,
              458
            ],
            [
              611,
              455
            ],
            [
              611,
              454
            ],
            [
              614,
              451
            ],
            [
              653,
              421
            ],
            [
              712,
              474
            ],
            [
              713,
              476
            ]
          ]
        ]
      },
      {
        "centroid": [
          610.3214285714286,
          480.0357142857143
        ],
        "area": [
          [
            [
              725,
              471
            ],
            [
              684,
              495
            ],
            [
              671,
              501
            ],
            [
              663,
              507
            ],
            [
              656,
              510
            ],
            [
              654,
              512
            ],
            [
              638,
              520
            ],
            [
              633,
              524
            ],
            [
              621,
              531
            ],
            [
              538,
              578
            ],
            [
              536,
              577
            ],
            [
              480,
              497
            ],
            [
              480,
              495
            ],
            [
              487,
              490
            ],
            [
              561,
              449
            ],
            [
              564,
              448
            ],
            [
              568,
              452
            ],
            [
              578,
              452
            ],
            [
              584,
              449
            ],
            [
              584,
              448
            ],
            [
              589,
              445
            ],
            [
              611,
              433
            ],
            [
              614,
              433
            ],
            [
              617,
              437
            ],
            [
              626,
              437
            ],
            [
              677,
              409
            ],
            [
              725,
              470
            ],
            [
              725,
              471
            ]
          ]
        ]
      },
      {
        "centroid": [
          633.44,
          453.32
        ],
        "area": [
          [
            [
              738,
              468
            ],
            [
              709,
              480
            ],
            [
              679,
              490
            ],
            [
              672,
              494
            ],
            [
              643,
              504
            ],
            [
              640,
              506
            ],
            [
              630,
              509
            ],
            [
              627,
              511
            ],
            [
              534,
              546
            ],
            [
              496,
              461
            ],
            [
              495,
              456
            ],
            [
              583,
              423
            ],
            [
              586,
              423
            ],
            [
              589,
              429
            ],
            [
              598,
              428
            ],
            [
              603,
              427
            ],
            [
              604,
              426
            ],
            [
              635,
              414
            ],
            [
              637,
              415
            ],
            [
              639,
              419
            ],
            [
              647,
              421
            ],
            [
              701,
              401
            ],
            [
              703,
              401
            ],
            [
              710,
              413
            ],
            [
              738,
              468
            ]
          ]
        ]
      },
      {
        "centroid": [
          649.8787878787879,
          449
        ],
        "area": [
          [
            [
              751,
              468
            ],
            [
              739,
              470
            ],
            [
              738,
              471
            ],
            [
              730,
              472
            ],
            [
              706,
              478
            ],
            [
              697,
              479
            ],
            [
              693,
              481
            ],
            [
              683,
              481
            ],
            [
              682,
              483
            ],
            [
              664,
              486
            ],
            [
              659,
              488
            ],
            [
              641,
              491
            ],
            [
              630,
              494
            ],
            [
              561,
              509
            ],
            [
              541,
              513
            ],
            [
              537,
              513
            ],
            [
              516,
              422
            ],
            [
              517,
              421
            ],
            [
              528,
              418
            ],
            [
              608,
              401
            ],
            [
              610,
              401
            ],
            [
              612,
              403
            ],
            [
              612,
              405
            ],
            [
              614,
              409
            ],
            [
              629,
              407
            ],
            [
              659,
              400
            ],
            [
              662,
              400
            ],
            [
              663,
              405
            ],
            [
              665,
              409
            ],
            [
              667,
              409
            ],
            [
              730,
              396
            ],
            [
              751,
              466
            ],
            [
              751,
              468
            ]
          ]
        ]
      }
    ]
  },
  {
    "houseId": "h2",
    "steps": [
      {
        "centroid": [
          755.6851851851852,
          653.9074074074074
        ],
        "area": [
          [
            [
              891,
              643
            ],
            [
              854,
              699
            ],
            [
              852,
              700
            ],
            [
              838,
              698
            ],
            [
              836,
              700
            ],
            [
              836,
              701
            ],
            [
              812,
              738
            ],
            [
              761,
              730
            ],
            [
              754,
              731
            ],
            [
              750,
              756
            ],
            [
              748,
              758
            ],
            [
              736,
              757
            ],
            [
              645,
              742
            ],
            [
              643,
              741
            ],
            [
              669,
              623
            ],
            [
              671,
              624
            ],
            [
              676,
              624
            ],
            [
              677,
              625
            ],
            [
              682,
              625
            ],
            [
              683,
              626
            ],
            [
              688,
              626
            ],
            [
              689,
              627
            ],
            [
              695,
              627
            ],
            [
              703,
              629
            ],
            [
              708,
              629
            ],
            [
              709,
              630
            ],
            [
              714,
              630
            ],
            [
              715,
              631
            ],
            [
              720,
              631
            ],
            [
              721,
              632
            ],
            [
              726,
              632
            ],
            [
              727,
              633
            ],
            [
              733,
              633
            ],
            [
              735,
              634
            ],
            [
              739,
              634
            ],
            [
              747,
              636
            ],
            [
              752,
              636
            ],
            [
              753,
              637
            ],
            [
              763,
              635
            ],
            [
              763,
              632
            ],
            [
              764,
              631
            ],
            [
              765,
              632
            ],
            [
              770,
              632
            ],
            [
              771,
              633
            ],
            [
              777,
              633
            ],
            [
              779,
              634
            ],
            [
              789,
              635
            ],
            [
              797,
              633
            ],
            [
              797,
              629
            ],
            [
              799,
              622
            ],
            [
              815,
              599
            ],
            [
              888,
              639
            ],
            [
              891,
              641
            ],
            [
              891,
              643
            ]
          ]
        ]
      },
      {
        "centroid": [
          727.7543859649123,
          650.1929824561404
        ],
        "area": [
          [
            [
              869,
              663
            ],
            [
              819,
              714
            ],
            [
              811,
              712
            ],
            [
              802,
              712
            ],
            [
              771,
              745
            ],
            [
              769,
              745
            ],
            [
              724,
              730
            ],
            [
              715,
              730
            ],
            [
              711,
              738
            ],
            [
              711,
              740
            ],
            [
              706,
              753
            ],
            [
              699,
              754
            ],
            [
              604,
              722
            ],
            [
              644,
              629
            ],
            [
              653,
              610
            ],
            [
              655,
              611
            ],
            [
              658,
              611
            ],
            [
              659,
              612
            ],
            [
              661,
              612
            ],
            [
              665,
              614
            ],
            [
              667,
              614
            ],
            [
              671,
              616
            ],
            [
              673,
              616
            ],
            [
              677,
              618
            ],
            [
              679,
              618
            ],
            [
              683,
              620
            ],
            [
              688,
              621
            ],
            [
              689,
              622
            ],
            [
              695,
              623
            ],
            [
              701,
              626
            ],
            [
              704,
              626
            ],
            [
              705,
              627
            ],
            [
              707,
              627
            ],
            [
              711,
              629
            ],
            [
              713,
              629
            ],
            [
              717,
              631
            ],
            [
              719,
              631
            ],
            [
              723,
              633
            ],
            [
              728,
              634
            ],
            [
              729,
              635
            ],
            [
              733,
              636
            ],
            [
              743,
              635
            ],
            [
              744,
              632
            ],
            [
              745,
              633
            ],
            [
              751,
              634
            ],
            [
              755,
              636
            ],
            [
              757,
              636
            ],
            [
              761,
              638
            ],
            [
              766,
              639
            ],
            [
              767,
              640
            ],
            [
              777,
              639
            ],
            [
              777,
              637
            ],
            [
              780,
              630
            ],
            [
              801,
              609
            ],
            [
              802,
              609
            ],
            [
              869,
              662
            ],
            [
              869,
              663
            ]
          ]
        ]
      },
      {
        "centroid": [
          743.4545454545455,
          662.3863636363636
        ],
        "area": [
          [
            [
              842,
              680
            ],
            [
              825,
              693
            ],
            [
              784,
              722
            ],
            [
              782,
              723
            ],
            [
              774,
              719
            ],
            [
              766,
              718
            ],
            [
              728,
              746
            ],
            [
              709,
              736
            ],
            [
              707,
              734
            ],
            [
              683,
              722
            ],
            [
              676,
              723
            ],
            [
              675,
              724
            ],
            [
              662,
              744
            ],
            [
              656,
              743
            ],
            [
              570,
              696
            ],
            [
              637,
              597
            ],
            [
              640,
              594
            ],
            [
              683,
              616
            ],
            [
              685,
              618
            ],
            [
              715,
              633
            ],
            [
              724,
              631
            ],
            [
              724,
              630
            ],
            [
              725,
              630
            ],
            [
              731,
              633
            ],
            [
              733,
              635
            ],
            [
              747,
              642
            ],
            [
              756,
              641
            ],
            [
              756,
              640
            ],
            [
              758,
              637
            ],
            [
              759,
              637
            ],
            [
              761,
              633
            ],
            [
              768,
              628
            ],
            [
              771,
              627
            ],
            [
              771,
              626
            ],
            [
              775,
              623
            ],
            [
              779,
              621
            ],
            [
              782,
              618
            ],
            [
              785,
              617
            ],
            [
              785,
              616
            ],
            [
              786,
              616
            ],
            [
              826,
              661
            ],
            [
              827,
              663
            ],
            [
              842,
              679
            ],
            [
              842,
              680
            ]
          ]
        ]
      },
      {
        "centroid": [
          696.8,
          682.275
        ],
        "area": [
          [
            [
              812,
              693
            ],
            [
              744,
              726
            ],
            [
              737,
              721
            ],
            [
              737,
              720
            ],
            [
              727,
              719
            ],
            [
              713,
              726
            ],
            [
              711,
              726
            ],
            [
              711,
              727
            ],
            [
              708,
              729
            ],
            [
              688,
              739
            ],
            [
              685,
              739
            ],
            [
              671,
              728
            ],
            [
              667,
              724
            ],
            [
              666,
              724
            ],
            [
              661,
              720
            ],
            [
              657,
              716
            ],
            [
              656,
              716
            ],
            [
              655,
              714
            ],
            [
              652,
              713
            ],
            [
              649,
              710
            ],
            [
              640,
              708
            ],
            [
              622,
              727
            ],
            [
              615,
              724
            ],
            [
              542,
              665
            ],
            [
              629,
              577
            ],
            [
              632,
              577
            ],
            [
              635,
              580
            ],
            [
              666,
              603
            ],
            [
              695,
              626
            ],
            [
              697,
              627
            ],
            [
              706,
              625
            ],
            [
              727,
              641
            ],
            [
              737,
              639
            ],
            [
              738,
              636
            ],
            [
              740,
              634
            ],
            [
              767,
              621
            ],
            [
              769,
              621
            ],
            [
              784,
              645
            ],
            [
              812,
              692
            ],
            [
              812,
              693
            ]
          ]
        ]
      },
      {
        "centroid": [
          670.5961538461538,
          679.3269230769231
        ],
        "area": [
          [
            [
              779,
              701
            ],
            [
              774,
              703
            ],
            [
              707,
              722
            ],
            [
              705,
              722
            ],
            [
              702,
              719
            ],
            [
              701,
              716
            ],
            [
              700,
              716
            ],
            [
              699,
              714
            ],
            [
              689,
              713
            ],
            [
              685,
              714
            ],
            [
              684,
              715
            ],
            [
              681,
              715
            ],
            [
              680,
              716
            ],
            [
              675,
              717
            ],
            [
              671,
              719
            ],
            [
              665,
              720
            ],
            [
              664,
              721
            ],
            [
              659,
              722
            ],
            [
              658,
              723
            ],
            [
              646,
              726
            ],
            [
              640,
              720
            ],
            [
              627,
              704
            ],
            [
              622,
              699
            ],
            [
              622,
              698
            ],
            [
              620,
              697
            ],
            [
              620,
              696
            ],
            [
              617,
              693
            ],
            [
              617,
              692
            ],
            [
              615,
              691
            ],
            [
              615,
              690
            ],
            [
              605,
              689
            ],
            [
              590,
              701
            ],
            [
              584,
              704
            ],
            [
              523,
              633
            ],
            [
              522,
              630
            ],
            [
              624,
              557
            ],
            [
              679,
              617
            ],
            [
              689,
              617
            ],
            [
              691,
              619
            ],
            [
              693,
              623
            ],
            [
              694,
              623
            ],
            [
              697,
              627
            ],
            [
              699,
              629
            ],
            [
              700,
              629
            ],
            [
              701,
              631
            ],
            [
              707,
              637
            ],
            [
              715,
              635
            ],
            [
              719,
              632
            ],
            [
              724,
              630
            ],
            [
              745,
              624
            ],
            [
              752,
              623
            ],
            [
              779,
              701
            ]
          ]
        ]
      },
      {
        "centroid": [
          653.8,
          644.5230769230769
        ],
        "area": [
          [
            [
              745,
              704
            ],
            [
              735,
              704
            ],
            [
              735,
              705
            ],
            [
              727,
              706
            ],
            [
              717,
              706
            ],
            [
              717,
              707
            ],
            [
              710,
              708
            ],
            [
              691,
              709
            ],
            [
              690,
              710
            ],
            [
              682,
              711
            ],
            [
              673,
              711
            ],
            [
              672,
              712
            ],
            [
              668,
              712
            ],
            [
              665,
              706
            ],
            [
              662,
              702
            ],
            [
              653,
              701
            ],
            [
              641,
              702
            ],
            [
              639,
              703
            ],
            [
              633,
              703
            ],
            [
              631,
              704
            ],
            [
              625,
              704
            ],
            [
              624,
              705
            ],
            [
              619,
              705
            ],
            [
              617,
              706
            ],
            [
              609,
              706
            ],
            [
              591,
              675
            ],
            [
              589,
              670
            ],
            [
              587,
              668
            ],
            [
              587,
              666
            ],
            [
              577,
              664
            ],
            [
              574,
              666
            ],
            [
              554,
              675
            ],
            [
              508,
              593
            ],
            [
              593,
              551
            ],
            [
              614,
              541
            ],
            [
              621,
              538
            ],
            [
              623,
              538
            ],
            [
              623,
              539
            ],
            [
              628,
              546
            ],
            [
              629,
              549
            ],
            [
              633,
              554
            ],
            [
              635,
              559
            ],
            [
              640,
              566
            ],
            [
              641,
              569
            ],
            [
              645,
              574
            ],
            [
              646,
              577
            ],
            [
              648,
              579
            ],
            [
              651,
              585
            ],
            [
              656,
              592
            ],
            [
              657,
              595
            ],
            [
              662,
              602
            ],
            [
              663,
              605
            ],
            [
              664,
              606
            ],
            [
              673,
              606
            ],
            [
              673,
              607
            ],
            [
              677,
              612
            ],
            [
              680,
              619
            ],
            [
              683,
              622
            ],
            [
              684,
              625
            ],
            [
              687,
              629
            ],
            [
              694,
              629
            ],
            [
              703,
              625
            ],
            [
              734,
              622
            ],
            [
              745,
              700
            ],
            [
              745,
              704
            ]
          ]
        ]
      },
      {
        "centroid": [
          634.063492063492,
          614.6031746031746
        ],
        "area": [
          [
            [
              716,
              626
            ],
            [
              715,
              627
            ],
            [
              715,
              642
            ],
            [
              714,
              643
            ],
            [
              714,
              658
            ],
            [
              713,
              661
            ],
            [
              713,
              675
            ],
            [
              712,
              677
            ],
            [
              712,
              690
            ],
            [
              711,
              691
            ],
            [
              711,
              700
            ],
            [
              695,
              700
            ],
            [
              687,
              699
            ],
            [
              641,
              697
            ],
            [
              634,
              696
            ],
            [
              631,
              689
            ],
            [
              627,
              683
            ],
            [
              619,
              683
            ],
            [
              617,
              682
            ],
            [
              588,
              682
            ],
            [
              578,
              681
            ],
            [
              563,
              636
            ],
            [
              551,
              635
            ],
            [
              550,
              636
            ],
            [
              545,
              637
            ],
            [
              544,
              638
            ],
            [
              539,
              639
            ],
            [
              538,
              640
            ],
            [
              535,
              640
            ],
            [
              531,
              642
            ],
            [
              506,
              566
            ],
            [
              503,
              554
            ],
            [
              532,
              545
            ],
            [
              551,
              540
            ],
            [
              565,
              535
            ],
            [
              621,
              518
            ],
            [
              624,
              518
            ],
            [
              626,
              521
            ],
            [
              629,
              529
            ],
            [
              631,
              537
            ],
            [
              632,
              538
            ],
            [
              634,
              543
            ],
            [
              634,
              545
            ],
            [
              637,
              551
            ],
            [
              639,
              559
            ],
            [
              641,
              562
            ],
            [
              642,
              567
            ],
            [
              645,
              573
            ],
            [
              645,
              575
            ],
            [
              646,
              576
            ],
            [
              647,
              581
            ],
            [
              649,
              584
            ],
            [
              650,
              589
            ],
            [
              651,
              590
            ],
            [
              660,
              593
            ],
            [
              661,
              597
            ],
            [
              664,
              603
            ],
            [
              665,
              609
            ],
            [
              669,
              617
            ],
            [
              675,
              619
            ],
            [
              681,
              617
            ],
            [
              716,
              618
            ],
            [
              716,
              626
            ]
          ]
        ]
      },
      {
        "centroid": [
          594.8059701492538,
          594.820895522388
        ],
        "area": [
          [
            [
              700,
              612
            ],
            [
              678,
              691
            ],
            [
              674,
              691
            ],
            [
              607,
              676
            ],
            [
              604,
              675
            ],
            [
              602,
              664
            ],
            [
              601,
              661
            ],
            [
              599,
              660
            ],
            [
              595,
              660
            ],
            [
              593,
              659
            ],
            [
              590,
              659
            ],
            [
              589,
              658
            ],
            [
              585,
              658
            ],
            [
              583,
              657
            ],
            [
              580,
              657
            ],
            [
              573,
              655
            ],
            [
              555,
              652
            ],
            [
              553,
              651
            ],
            [
              552,
              649
            ],
            [
              551,
              641
            ],
            [
              551,
              631
            ],
            [
              550,
              631
            ],
            [
              547,
              604
            ],
            [
              539,
              602
            ],
            [
              533,
              602
            ],
            [
              532,
              603
            ],
            [
              527,
              603
            ],
            [
              525,
              604
            ],
            [
              515,
              605
            ],
            [
              513,
              590
            ],
            [
              513,
              581
            ],
            [
              512,
              580
            ],
            [
              510,
              562
            ],
            [
              510,
              553
            ],
            [
              509,
              552
            ],
            [
              507,
              535
            ],
            [
              507,
              525
            ],
            [
              506,
              525
            ],
            [
              505,
              516
            ],
            [
              507,
              515
            ],
            [
              630,
              498
            ],
            [
              631,
              500
            ],
            [
              632,
              511
            ],
            [
              633,
              513
            ],
            [
              633,
              517
            ],
            [
              634,
              520
            ],
            [
              634,
              525
            ],
            [
              635,
              527
            ],
            [
              635,
              531
            ],
            [
              637,
              540
            ],
            [
              638,
              553
            ],
            [
              639,
              554
            ],
            [
              639,
              559
            ],
            [
              641,
              567
            ],
            [
              641,
              573
            ],
            [
              650,
              578
            ],
            [
              650,
              581
            ],
            [
              651,
              583
            ],
            [
              651,
              591
            ],
            [
              652,
              593
            ],
            [
              652,
              597
            ],
            [
              653,
              598
            ],
            [
              654,
              605
            ],
            [
              655,
              606
            ],
            [
              671,
              605
            ],
            [
              699,
              611
            ],
            [
              700,
              612
            ]
          ]
        ]
      },
      {
        "centroid": [
          604.8627450980392,
          561.0588235294117
        ],
        "area": [
          [
            [
              685,
              603
            ],
            [
              648,
              677
            ],
            [
              646,
              677
            ],
            [
              631,
              671
            ],
            [
              579,
              649
            ],
            [
              579,
              635
            ],
            [
              575,
              633
            ],
            [
              573,
              633
            ],
            [
              568,
              631
            ],
            [
              565,
              629
            ],
            [
              563,
              629
            ],
            [
              557,
              626
            ],
            [
              555,
              626
            ],
            [
              537,
              619
            ],
            [
              534,
              616
            ],
            [
              538,
              571
            ],
            [
              537,
              567
            ],
            [
              507,
              567
            ],
            [
              515,
              481
            ],
            [
              516,
              478
            ],
            [
              530,
              478
            ],
            [
              536,
              479
            ],
            [
              560,
              479
            ],
            [
              561,
              480
            ],
            [
              588,
              479
            ],
            [
              589,
              480
            ],
            [
              615,
              480
            ],
            [
              617,
              481
            ],
            [
              627,
              481
            ],
            [
              635,
              480
            ],
            [
              640,
              481
            ],
            [
              640,
              493
            ],
            [
              639,
              495
            ],
            [
              639,
              510
            ],
            [
              638,
              511
            ],
            [
              638,
              529
            ],
            [
              637,
              531
            ],
            [
              637,
              545
            ],
            [
              636,
              547
            ],
            [
              636,
              553
            ],
            [
              638,
              561
            ],
            [
              640,
              562
            ],
            [
              643,
              562
            ],
            [
              643,
              570
            ],
            [
              642,
              573
            ],
            [
              642,
              589
            ],
            [
              646,
              591
            ],
            [
              657,
              591
            ],
            [
              681,
              600
            ],
            [
              685,
              602
            ],
            [
              685,
              603
            ]
          ]
        ]
      },
      {
        "centroid": [
          601.7301587301587,
          551.5079365079365
        ],
        "area": [
          [
            [
              672,
              592
            ],
            [
              623,
              656
            ],
            [
              621,
              658
            ],
            [
              620,
              658
            ],
            [
              560,
              620
            ],
            [
              561,
              615
            ],
            [
              561,
              605
            ],
            [
              559,
              603
            ],
            [
              556,
              602
            ],
            [
              549,
              597
            ],
            [
              546,
              596
            ],
            [
              539,
              591
            ],
            [
              536,
              590
            ],
            [
              529,
              585
            ],
            [
              526,
              584
            ],
            [
              524,
              582
            ],
            [
              525,
              574
            ],
            [
              535,
              541
            ],
            [
              533,
              533
            ],
            [
              531,
              532
            ],
            [
              522,
              531
            ],
            [
              521,
              530
            ],
            [
              516,
              530
            ],
            [
              515,
              529
            ],
            [
              510,
              529
            ],
            [
              509,
              528
            ],
            [
              507,
              528
            ],
            [
              533,
              443
            ],
            [
              653,
              464
            ],
            [
              652,
              472
            ],
            [
              651,
              473
            ],
            [
              649,
              483
            ],
            [
              647,
              487
            ],
            [
              647,
              490
            ],
            [
              646,
              491
            ],
            [
              646,
              494
            ],
            [
              645,
              495
            ],
            [
              644,
              502
            ],
            [
              643,
              503
            ],
            [
              640,
              517
            ],
            [
              638,
              521
            ],
            [
              638,
              524
            ],
            [
              637,
              525
            ],
            [
              637,
              528
            ],
            [
              636,
              529
            ],
            [
              635,
              533
            ],
            [
              636,
              544
            ],
            [
              640,
              544
            ],
            [
              640,
              545
            ],
            [
              638,
              549
            ],
            [
              638,
              553
            ],
            [
              635,
              559
            ],
            [
              634,
              565
            ],
            [
              636,
              573
            ],
            [
              644,
              574
            ],
            [
              644,
              575
            ],
            [
              647,
              575
            ],
            [
              649,
              576
            ],
            [
              652,
              578
            ],
            [
              652,
              579
            ],
            [
              657,
              581
            ],
            [
              672,
              590
            ],
            [
              672,
              592
            ]
          ]
        ]
      },
      {
        "centroid": [
          602.0689655172414,
          527.9310344827586
        ],
        "area": [
          [
            [
              670,
              451
            ],
            [
              663,
              465
            ],
            [
              662,
              465
            ],
            [
              662,
              467
            ],
            [
              637,
              517
            ],
            [
              638,
              526
            ],
            [
              639,
              526
            ],
            [
              640,
              528
            ],
            [
              636,
              536
            ],
            [
              634,
              537
            ],
            [
              634,
              539
            ],
            [
              632,
              541
            ],
            [
              630,
              545
            ],
            [
              632,
              555
            ],
            [
              636,
              556
            ],
            [
              636,
              557
            ],
            [
              639,
              557
            ],
            [
              641,
              558
            ],
            [
              648,
              565
            ],
            [
              649,
              565
            ],
            [
              650,
              567
            ],
            [
              651,
              567
            ],
            [
              652,
              569
            ],
            [
              654,
              570
            ],
            [
              654,
              571
            ],
            [
              656,
              571
            ],
            [
              662,
              577
            ],
            [
              662,
              578
            ],
            [
              654,
              586
            ],
            [
              605,
              630
            ],
            [
              599,
              635
            ],
            [
              598,
              635
            ],
            [
              548,
              588
            ],
            [
              548,
              586
            ],
            [
              551,
              580
            ],
            [
              551,
              573
            ],
            [
              539,
              562
            ],
            [
              538,
              562
            ],
            [
              531,
              555
            ],
            [
              529,
              554
            ],
            [
              520,
              545
            ],
            [
              524,
              536
            ],
            [
              539,
              509
            ],
            [
              539,
              499
            ],
            [
              535,
              498
            ],
            [
              529,
              495
            ],
            [
              524,
              494
            ],
            [
              521,
              492
            ],
            [
              516,
              491
            ],
            [
              516,
              489
            ],
            [
              557,
              412
            ],
            [
              559,
              412
            ],
            [
              565,
              414
            ],
            [
              566,
              415
            ],
            [
              600,
              426
            ],
            [
              601,
              427
            ],
            [
              649,
              443
            ],
            [
              670,
              451
            ]
          ]
        ]
      },
      {
        "centroid": [
          594.7272727272727,
          502.09090909090907
        ],
        "area": [
          [
            [
              688,
              440
            ],
            [
              682,
              449
            ],
            [
              660,
              478
            ],
            [
              657,
              481
            ],
            [
              656,
              481
            ],
            [
              656,
              483
            ],
            [
              642,
              501
            ],
            [
              644,
              510
            ],
            [
              643,
              512
            ],
            [
              641,
              513
            ],
            [
              641,
              515
            ],
            [
              636,
              519
            ],
            [
              631,
              527
            ],
            [
              632,
              537
            ],
            [
              633,
              537
            ],
            [
              634,
              539
            ],
            [
              636,
              539
            ],
            [
              640,
              542
            ],
            [
              643,
              546
            ],
            [
              644,
              549
            ],
            [
              645,
              549
            ],
            [
              646,
              551
            ],
            [
              647,
              551
            ],
            [
              652,
              558
            ],
            [
              652,
              559
            ],
            [
              653,
              559
            ],
            [
              656,
              563
            ],
            [
              582,
              609
            ],
            [
              579,
              606
            ],
            [
              544,
              558
            ],
            [
              543,
              556
            ],
            [
              543,
              553
            ],
            [
              547,
              549
            ],
            [
              547,
              539
            ],
            [
              545,
              537
            ],
            [
              543,
              533
            ],
            [
              542,
              533
            ],
            [
              531,
              519
            ],
            [
              525,
              510
            ],
            [
              526,
              506
            ],
            [
              529,
              502
            ],
            [
              531,
              501
            ],
            [
              531,
              500
            ],
            [
              536,
              493
            ],
            [
              537,
              493
            ],
            [
              537,
              492
            ],
            [
              539,
              491
            ],
            [
              541,
              487
            ],
            [
              545,
              483
            ],
            [
              545,
              482
            ],
            [
              551,
              475
            ],
            [
              551,
              467
            ],
            [
              547,
              464
            ],
            [
              542,
              462
            ],
            [
              535,
              458
            ],
            [
              533,
              456
            ],
            [
              532,
              456
            ],
            [
              531,
              455
            ],
            [
              532,
              453
            ],
            [
              587,
              385
            ],
            [
              596,
              389
            ],
            [
              602,
              393
            ],
            [
              631,
              408
            ],
            [
              650,
              419
            ],
            [
              686,
              438
            ],
            [
              688,
              440
            ]
          ]
        ]
      },
      {
        "centroid": [
          597.0943396226415,
          480.1320754716981
        ],
        "area": [
          [
            [
              709,
              432
            ],
            [
              651,
              485
            ],
            [
              651,
              494
            ],
            [
              649,
              496
            ],
            [
              648,
              496
            ],
            [
              647,
              498
            ],
            [
              643,
              502
            ],
            [
              640,
              503
            ],
            [
              640,
              504
            ],
            [
              638,
              505
            ],
            [
              634,
              510
            ],
            [
              635,
              519
            ],
            [
              636,
              521
            ],
            [
              638,
              521
            ],
            [
              640,
              525
            ],
            [
              641,
              525
            ],
            [
              652,
              548
            ],
            [
              640,
              552
            ],
            [
              637,
              554
            ],
            [
              628,
              557
            ],
            [
              623,
              560
            ],
            [
              571,
              581
            ],
            [
              569,
              578
            ],
            [
              545,
              526
            ],
            [
              544,
              521
            ],
            [
              545,
              521
            ],
            [
              551,
              515
            ],
            [
              551,
              507
            ],
            [
              536,
              476
            ],
            [
              536,
              474
            ],
            [
              543,
              467
            ],
            [
              545,
              466
            ],
            [
              550,
              461
            ],
            [
              551,
              461
            ],
            [
              559,
              453
            ],
            [
              561,
              453
            ],
            [
              561,
              452
            ],
            [
              563,
              451
            ],
            [
              563,
              450
            ],
            [
              566,
              447
            ],
            [
              567,
              447
            ],
            [
              569,
              437
            ],
            [
              565,
              433
            ],
            [
              564,
              433
            ],
            [
              555,
              425
            ],
            [
              554,
              425
            ],
            [
              553,
              423
            ],
            [
              560,
              416
            ],
            [
              569,
              409
            ],
            [
              608,
              374
            ],
            [
              621,
              363
            ],
            [
              622,
              363
            ],
            [
              709,
              432
            ]
          ]
        ]
      },
      {
        "centroid": [
          621.984126984127,
          443.9047619047619
        ],
        "area": [
          [
            [
              730,
              428
            ],
            [
              685,
              457
            ],
            [
              675,
              462
            ],
            [
              671,
              466
            ],
            [
              663,
              471
            ],
            [
              662,
              479
            ],
            [
              661,
              480
            ],
            [
              655,
              484
            ],
            [
              650,
              486
            ],
            [
              648,
              488
            ],
            [
              646,
              488
            ],
            [
              644,
              491
            ],
            [
              641,
              493
            ],
            [
              641,
              501
            ],
            [
              645,
              507
            ],
            [
              652,
              532
            ],
            [
              566,
              552
            ],
            [
              552,
              494
            ],
            [
              552,
              490
            ],
            [
              553,
              489
            ],
            [
              555,
              489
            ],
            [
              556,
              487
            ],
            [
              561,
              485
            ],
            [
              562,
              475
            ],
            [
              560,
              471
            ],
            [
              558,
              461
            ],
            [
              557,
              460
            ],
            [
              557,
              457
            ],
            [
              556,
              456
            ],
            [
              556,
              453
            ],
            [
              554,
              447
            ],
            [
              554,
              442
            ],
            [
              555,
              442
            ],
            [
              557,
              440
            ],
            [
              563,
              437
            ],
            [
              568,
              433
            ],
            [
              571,
              432
            ],
            [
              573,
              430
            ],
            [
              579,
              427
            ],
            [
              581,
              425
            ],
            [
              583,
              425
            ],
            [
              583,
              424
            ],
            [
              589,
              421
            ],
            [
              591,
              419
            ],
            [
              593,
              411
            ],
            [
              585,
              401
            ],
            [
              580,
              396
            ],
            [
              580,
              395
            ],
            [
              648,
              353
            ],
            [
              659,
              347
            ],
            [
              673,
              362
            ],
            [
              681,
              372
            ],
            [
              688,
              379
            ],
            [
              689,
              381
            ],
            [
              696,
              388
            ],
            [
              697,
              390
            ],
            [
              705,
              398
            ],
            [
              712,
              407
            ],
            [
              720,
              415
            ],
            [
              721,
              417
            ],
            [
              727,
              423
            ],
            [
              730,
              427
            ],
            [
              730,
              428
            ]
          ]
        ]
      },
      {
        "centroid": [
          625.1666666666666,
          450.537037037037
        ],
        "area": [
          [
            [
              752,
              426
            ],
            [
              747,
              429
            ],
            [
              703,
              448
            ],
            [
              691,
              452
            ],
            [
              677,
              459
            ],
            [
              675,
              467
            ],
            [
              654,
              475
            ],
            [
              654,
              476
            ],
            [
              652,
              477
            ],
            [
              650,
              485
            ],
            [
              652,
              489
            ],
            [
              654,
              507
            ],
            [
              654,
              516
            ],
            [
              643,
              518
            ],
            [
              623,
              518
            ],
            [
              622,
              519
            ],
            [
              611,
              519
            ],
            [
              610,
              520
            ],
            [
              602,
              521
            ],
            [
              589,
              521
            ],
            [
              589,
              522
            ],
            [
              577,
              522
            ],
            [
              574,
              523
            ],
            [
              568,
              523
            ],
            [
              567,
              521
            ],
            [
              567,
              495
            ],
            [
              566,
              494
            ],
            [
              566,
              461
            ],
            [
              571,
              458
            ],
            [
              573,
              458
            ],
            [
              577,
              456
            ],
            [
              578,
              447
            ],
            [
              578,
              433
            ],
            [
              577,
              432
            ],
            [
              577,
              415
            ],
            [
              580,
              413
            ],
            [
              587,
              410
            ],
            [
              589,
              410
            ],
            [
              594,
              407
            ],
            [
              599,
              405
            ],
            [
              601,
              405
            ],
            [
              604,
              403
            ],
            [
              611,
              400
            ],
            [
              613,
              400
            ],
            [
              619,
              397
            ],
            [
              621,
              389
            ],
            [
              615,
              377
            ],
            [
              612,
              373
            ],
            [
              613,
              372
            ],
            [
              682,
              343
            ],
            [
              697,
              337
            ],
            [
              699,
              337
            ],
            [
              751,
              423
            ],
            [
              752,
              426
            ]
          ]
        ]
      },
      {
        "centroid": [
          652.9454545454546,
          420.72727272727275
        ],
        "area": [
          [
            [
              774,
              429
            ],
            [
              723,
              442
            ],
            [
              711,
              444
            ],
            [
              703,
              447
            ],
            [
              691,
              450
            ],
            [
              690,
              456
            ],
            [
              689,
              457
            ],
            [
              678,
              459
            ],
            [
              669,
              462
            ],
            [
              663,
              463
            ],
            [
              662,
              464
            ],
            [
              663,
              473
            ],
            [
              663,
              478
            ],
            [
              662,
              479
            ],
            [
              662,
              488
            ],
            [
              661,
              496
            ],
            [
              659,
              502
            ],
            [
              638,
              501
            ],
            [
              629,
              499
            ],
            [
              615,
              499
            ],
            [
              579,
              496
            ],
            [
              575,
              495
            ],
            [
              577,
              477
            ],
            [
              585,
              434
            ],
            [
              597,
              432
            ],
            [
              599,
              426
            ],
            [
              605,
              391
            ],
            [
              612,
              389
            ],
            [
              615,
              389
            ],
            [
              616,
              388
            ],
            [
              619,
              388
            ],
            [
              620,
              387
            ],
            [
              623,
              387
            ],
            [
              624,
              386
            ],
            [
              627,
              386
            ],
            [
              628,
              385
            ],
            [
              635,
              384
            ],
            [
              636,
              383
            ],
            [
              639,
              383
            ],
            [
              640,
              382
            ],
            [
              643,
              382
            ],
            [
              644,
              381
            ],
            [
              647,
              381
            ],
            [
              648,
              380
            ],
            [
              651,
              380
            ],
            [
              653,
              371
            ],
            [
              650,
              363
            ],
            [
              650,
              361
            ],
            [
              648,
              357
            ],
            [
              648,
              355
            ],
            [
              651,
              354
            ],
            [
              736,
              333
            ],
            [
              740,
              333
            ],
            [
              773,
              424
            ],
            [
              774,
              429
            ]
          ]
        ]
      },
      {
        "centroid": [
          695.52,
          415.8
        ],
        "area": [
          [
            [
              795,
              434
            ],
            [
              789,
              435
            ],
            [
              779,
              435
            ],
            [
              779,
              436
            ],
            [
              769,
              436
            ],
            [
              768,
              437
            ],
            [
              759,
              437
            ],
            [
              758,
              438
            ],
            [
              747,
              438
            ],
            [
              747,
              439
            ],
            [
              733,
              439
            ],
            [
              730,
              440
            ],
            [
              707,
              443
            ],
            [
              707,
              449
            ],
            [
              695,
              449
            ],
            [
              694,
              450
            ],
            [
              678,
              452
            ],
            [
              677,
              464
            ],
            [
              671,
              482
            ],
            [
              668,
              488
            ],
            [
              662,
              488
            ],
            [
              641,
              482
            ],
            [
              625,
              479
            ],
            [
              588,
              470
            ],
            [
              587,
              469
            ],
            [
              599,
              436
            ],
            [
              609,
              412
            ],
            [
              610,
              411
            ],
            [
              619,
              411
            ],
            [
              623,
              410
            ],
            [
              632,
              384
            ],
            [
              637,
              372
            ],
            [
              645,
              372
            ],
            [
              647,
              371
            ],
            [
              655,
              371
            ],
            [
              657,
              370
            ],
            [
              667,
              370
            ],
            [
              668,
              369
            ],
            [
              677,
              369
            ],
            [
              678,
              368
            ],
            [
              687,
              368
            ],
            [
              688,
              359
            ],
            [
              687,
              358
            ],
            [
              686,
              343
            ],
            [
              713,
              341
            ],
            [
              780,
              334
            ],
            [
              781,
              336
            ],
            [
              788,
              382
            ],
            [
              795,
              430
            ],
            [
              795,
              434
            ]
          ]
        ]
      },
      {
        "centroid": [
          739.2954545454545,
          395.09090909090907
        ],
        "area": [
          [
            [
              820,
              351
            ],
            [
              819,
              351
            ],
            [
              819,
              370
            ],
            [
              818,
              373
            ],
            [
              818,
              389
            ],
            [
              817,
              389
            ],
            [
              817,
              408
            ],
            [
              816,
              411
            ],
            [
              816,
              427
            ],
            [
              815,
              429
            ],
            [
              815,
              442
            ],
            [
              798,
              442
            ],
            [
              727,
              438
            ],
            [
              724,
              440
            ],
            [
              724,
              443
            ],
            [
              693,
              443
            ],
            [
              692,
              455
            ],
            [
              683,
              471
            ],
            [
              678,
              477
            ],
            [
              672,
              475
            ],
            [
              663,
              470
            ],
            [
              658,
              469
            ],
            [
              650,
              466
            ],
            [
              603,
              446
            ],
            [
              635,
              395
            ],
            [
              637,
              393
            ],
            [
              651,
              393
            ],
            [
              671,
              359
            ],
            [
              686,
              359
            ],
            [
              687,
              360
            ],
            [
              704,
              360
            ],
            [
              705,
              361
            ],
            [
              715,
              361
            ],
            [
              723,
              359
            ],
            [
              725,
              337
            ],
            [
              729,
              338
            ],
            [
              745,
              338
            ],
            [
              745,
              339
            ],
            [
              781,
              340
            ],
            [
              787,
              341
            ],
            [
              803,
              341
            ],
            [
              805,
              342
            ],
            [
              820,
              342
            ],
            [
              820,
              351
            ]
          ]
        ]
      },
      {
        "centroid": [
          747.4468085106383,
          396.0425531914894
        ],
        "area": [
          [
            [
              857,
              359
            ],
            [
              848,
              394
            ],
            [
              845,
              403
            ],
            [
              844,
              410
            ],
            [
              834,
              449
            ],
            [
              832,
              454
            ],
            [
              783,
              444
            ],
            [
              777,
              442
            ],
            [
              745,
              436
            ],
            [
              743,
              438
            ],
            [
              743,
              441
            ],
            [
              738,
              441
            ],
            [
              737,
              440
            ],
            [
              727,
              438
            ],
            [
              713,
              437
            ],
            [
              711,
              438
            ],
            [
              711,
              441
            ],
            [
              710,
              442
            ],
            [
              709,
              447
            ],
            [
              707,
              448
            ],
            [
              707,
              450
            ],
            [
              691,
              468
            ],
            [
              624,
              426
            ],
            [
              637,
              411
            ],
            [
              667,
              379
            ],
            [
              671,
              380
            ],
            [
              683,
              379
            ],
            [
              707,
              351
            ],
            [
              712,
              351
            ],
            [
              713,
              352
            ],
            [
              723,
              353
            ],
            [
              729,
              355
            ],
            [
              732,
              355
            ],
            [
              733,
              356
            ],
            [
              737,
              356
            ],
            [
              739,
              357
            ],
            [
              742,
              357
            ],
            [
              743,
              358
            ],
            [
              751,
              359
            ],
            [
              759,
              357
            ],
            [
              759,
              355
            ],
            [
              761,
              351
            ],
            [
              761,
              349
            ],
            [
              765,
              337
            ],
            [
              856,
              355
            ],
            [
              857,
              356
            ],
            [
              857,
              359
            ]
          ]
        ]
      },
      {
        "centroid": [
          770.55,
          399.6666666666667
        ],
        "area": [
          [
            [
              892,
              375
            ],
            [
              870,
              419
            ],
            [
              867,
              427
            ],
            [
              859,
              441
            ],
            [
              858,
              446
            ],
            [
              847,
              467
            ],
            [
              845,
              467
            ],
            [
              817,
              456
            ],
            [
              802,
              451
            ],
            [
              787,
              444
            ],
            [
              781,
              443
            ],
            [
              767,
              438
            ],
            [
              763,
              438
            ],
            [
              761,
              441
            ],
            [
              758,
              441
            ],
            [
              753,
              438
            ],
            [
              747,
              437
            ],
            [
              739,
              434
            ],
            [
              731,
              434
            ],
            [
              728,
              440
            ],
            [
              728,
              442
            ],
            [
              727,
              443
            ],
            [
              725,
              443
            ],
            [
              724,
              445
            ],
            [
              720,
              449
            ],
            [
              719,
              449
            ],
            [
              709,
              458
            ],
            [
              705,
              460
            ],
            [
              649,
              410
            ],
            [
              649,
              409
            ],
            [
              651,
              407
            ],
            [
              699,
              370
            ],
            [
              701,
              370
            ],
            [
              705,
              372
            ],
            [
              717,
              371
            ],
            [
              717,
              370
            ],
            [
              719,
              368
            ],
            [
              744,
              348
            ],
            [
              747,
              348
            ],
            [
              753,
              351
            ],
            [
              756,
              351
            ],
            [
              759,
              353
            ],
            [
              764,
              354
            ],
            [
              767,
              356
            ],
            [
              769,
              356
            ],
            [
              785,
              362
            ],
            [
              794,
              361
            ],
            [
              796,
              357
            ],
            [
              797,
              357
            ],
            [
              797,
              355
            ],
            [
              798,
              353
            ],
            [
              799,
              353
            ],
            [
              799,
              351
            ],
            [
              801,
              349
            ],
            [
              801,
              347
            ],
            [
              803,
              344
            ],
            [
              805,
              343
            ],
            [
              879,
              369
            ],
            [
              892,
              374
            ],
            [
              892,
              375
            ]
          ]
        ]
      },
      {
        "centroid": [
          801.0363636363636,
          416.6545454545454
        ],
        "area": [
          [
            [
              922,
              398
            ],
            [
              899,
              430
            ],
            [
              893,
              436
            ],
            [
              889,
              443
            ],
            [
              880,
              454
            ],
            [
              876,
              461
            ],
            [
              864,
              477
            ],
            [
              862,
              478
            ],
            [
              858,
              473
            ],
            [
              857,
              470
            ],
            [
              847,
              468
            ],
            [
              839,
              472
            ],
            [
              816,
              459
            ],
            [
              807,
              453
            ],
            [
              807,
              452
            ],
            [
              802,
              451
            ],
            [
              789,
              444
            ],
            [
              781,
              442
            ],
            [
              779,
              444
            ],
            [
              776,
              444
            ],
            [
              771,
              440
            ],
            [
              759,
              434
            ],
            [
              750,
              435
            ],
            [
              750,
              436
            ],
            [
              746,
              441
            ],
            [
              743,
              441
            ],
            [
              742,
              443
            ],
            [
              741,
              443
            ],
            [
              734,
              448
            ],
            [
              729,
              450
            ],
            [
              727,
              452
            ],
            [
              723,
              454
            ],
            [
              720,
              454
            ],
            [
              675,
              398
            ],
            [
              675,
              397
            ],
            [
              733,
              365
            ],
            [
              735,
              367
            ],
            [
              739,
              369
            ],
            [
              749,
              369
            ],
            [
              781,
              350
            ],
            [
              783,
              350
            ],
            [
              789,
              354
            ],
            [
              794,
              356
            ],
            [
              807,
              363
            ],
            [
              809,
              365
            ],
            [
              821,
              371
            ],
            [
              829,
              369
            ],
            [
              832,
              365
            ],
            [
              833,
              365
            ],
            [
              834,
              362
            ],
            [
              838,
              357
            ],
            [
              839,
              357
            ],
            [
              841,
              353
            ],
            [
              921,
              396
            ],
            [
              922,
              398
            ]
          ]
        ]
      },
      {
        "centroid": [
          810.0204081632653,
          414
        ],
        "area": [
          [
            [
              948,
              425
            ],
            [
              868,
              500
            ],
            [
              826,
              467
            ],
            [
              820,
              461
            ],
            [
              816,
              459
            ],
            [
              806,
              451
            ],
            [
              796,
              451
            ],
            [
              793,
              449
            ],
            [
              793,
              448
            ],
            [
              792,
              448
            ],
            [
              789,
              444
            ],
            [
              786,
              443
            ],
            [
              783,
              441
            ],
            [
              783,
              440
            ],
            [
              779,
              438
            ],
            [
              770,
              437
            ],
            [
              768,
              439
            ],
            [
              765,
              440
            ],
            [
              764,
              442
            ],
            [
              762,
              443
            ],
            [
              757,
              444
            ],
            [
              751,
              447
            ],
            [
              749,
              447
            ],
            [
              741,
              451
            ],
            [
              736,
              451
            ],
            [
              703,
              389
            ],
            [
              704,
              388
            ],
            [
              765,
              366
            ],
            [
              767,
              366
            ],
            [
              775,
              372
            ],
            [
              783,
              371
            ],
            [
              789,
              369
            ],
            [
              790,
              368
            ],
            [
              795,
              367
            ],
            [
              796,
              366
            ],
            [
              813,
              359
            ],
            [
              819,
              358
            ],
            [
              851,
              383
            ],
            [
              861,
              383
            ],
            [
              867,
              377
            ],
            [
              871,
              375
            ],
            [
              871,
              374
            ],
            [
              873,
              373
            ],
            [
              873,
              372
            ],
            [
              875,
              371
            ],
            [
              876,
              369
            ],
            [
              938,
              416
            ],
            [
              947,
              423
            ],
            [
              948,
              425
            ]
          ]
        ]
      },
      {
        "centroid": [
          829.5227272727273,
          434.5681818181818
        ],
        "area": [
          [
            [
              969,
              455
            ],
            [
              915,
              491
            ],
            [
              904,
              497
            ],
            [
              897,
              503
            ],
            [
              874,
              518
            ],
            [
              866,
              510
            ],
            [
              822,
              461
            ],
            [
              813,
              458
            ],
            [
              811,
              459
            ],
            [
              807,
              452
            ],
            [
              805,
              450
            ],
            [
              804,
              450
            ],
            [
              803,
              448
            ],
            [
              802,
              448
            ],
            [
              801,
              446
            ],
            [
              800,
              446
            ],
            [
              797,
              443
            ],
            [
              797,
              442
            ],
            [
              787,
              441
            ],
            [
              785,
              442
            ],
            [
              783,
              444
            ],
            [
              783,
              445
            ],
            [
              767,
              449
            ],
            [
              763,
              449
            ],
            [
              762,
              450
            ],
            [
              755,
              450
            ],
            [
              753,
              452
            ],
            [
              734,
              391
            ],
            [
              733,
              385
            ],
            [
              743,
              382
            ],
            [
              797,
              371
            ],
            [
              801,
              372
            ],
            [
              801,
              373
            ],
            [
              807,
              379
            ],
            [
              821,
              377
            ],
            [
              852,
              370
            ],
            [
              881,
              401
            ],
            [
              889,
              401
            ],
            [
              907,
              390
            ],
            [
              924,
              407
            ],
            [
              927,
              411
            ],
            [
              944,
              428
            ],
            [
              944,
              429
            ],
            [
              969,
              455
            ]
          ]
        ]
      }
    ]
  },
  {
    "houseId": "h3",
    "steps": [
      {
        "centroid": [
          594.25,
          647.625
        ],
        "area": [
          [
            [
              680,
              578
            ],
            [
              671,
              618
            ],
            [
              664,
              619
            ],
            [
              641,
              723
            ],
            [
              636,
              723
            ],
            [
              569,
              712
            ],
            [
              566,
              716
            ],
            [
              566,
              719
            ],
            [
              565,
              721
            ],
            [
              456,
              703
            ],
            [
              456,
              701
            ],
            [
              498,
              549
            ],
            [
              503,
              549
            ],
            [
              677,
              576
            ],
            [
              680,
              577
            ],
            [
              680,
              578
            ]
          ]
        ]
      },
      {
        "centroid": [
          570.1176470588235,
          621.9411764705883
        ],
        "area": [
          [
            [
              673,
              566
            ],
            [
              661,
              592
            ],
            [
              656,
              605
            ],
            [
              647,
              609
            ],
            [
              645,
              613
            ],
            [
              606,
              703
            ],
            [
              603,
              703
            ],
            [
              541,
              682
            ],
            [
              536,
              682
            ],
            [
              533,
              689
            ],
            [
              532,
              689
            ],
            [
              434,
              656
            ],
            [
              431,
              654
            ],
            [
              490,
              534
            ],
            [
              502,
              511
            ],
            [
              529,
              519
            ],
            [
              673,
              566
            ]
          ]
        ]
      },
      {
        "centroid": [
          557.375,
          601.5416666666666
        ],
        "area": [
          [
            [
              667,
              555
            ],
            [
              655,
              573
            ],
            [
              651,
              577
            ],
            [
              644,
              589
            ],
            [
              635,
              590
            ],
            [
              620,
              613
            ],
            [
              577,
              676
            ],
            [
              575,
              678
            ],
            [
              568,
              675
            ],
            [
              529,
              654
            ],
            [
              527,
              652
            ],
            [
              518,
              648
            ],
            [
              511,
              648
            ],
            [
              508,
              652
            ],
            [
              506,
              652
            ],
            [
              417,
              604
            ],
            [
              419,
              600
            ],
            [
              433,
              581
            ],
            [
              445,
              566
            ],
            [
              473,
              527
            ],
            [
              513,
              474
            ],
            [
              652,
              545
            ],
            [
              667,
              553
            ],
            [
              667,
              555
            ]
          ]
        ]
      },
      {
        "centroid": [
          520.3333333333334,
          546.3333333333334
        ],
        "area": [
          [
            [
              665,
              541
            ],
            [
              635,
              572
            ],
            [
              625,
              572
            ],
            [
              625,
              573
            ],
            [
              550,
              649
            ],
            [
              506,
              614
            ],
            [
              505,
              612
            ],
            [
              503,
              611
            ],
            [
              503,
              610
            ],
            [
              493,
              610
            ],
            [
              490,
              613
            ],
            [
              487,
              611
            ],
            [
              417,
              555
            ],
            [
              413,
              551
            ],
            [
              419,
              546
            ],
            [
              435,
              530
            ],
            [
              437,
              529
            ],
            [
              454,
              512
            ],
            [
              476,
              491
            ],
            [
              477,
              491
            ],
            [
              496,
              472
            ],
            [
              497,
              472
            ],
            [
              530,
              440
            ],
            [
              533,
              441
            ],
            [
              548,
              452
            ],
            [
              665,
              540
            ],
            [
              665,
              541
            ]
          ]
        ]
      },
      {
        "centroid": [
          586.1515151515151,
          517.2121212121212
        ],
        "area": [
          [
            [
              665,
              528
            ],
            [
              652,
              538
            ],
            [
              639,
              546
            ],
            [
              638,
              548
            ],
            [
              631,
              553
            ],
            [
              629,
              554
            ],
            [
              621,
              552
            ],
            [
              619,
              554
            ],
            [
              617,
              554
            ],
            [
              617,
              555
            ],
            [
              615,
              557
            ],
            [
              607,
              562
            ],
            [
              601,
              567
            ],
            [
              532,
              616
            ],
            [
              493,
              570
            ],
            [
              483,
              570
            ],
            [
              481,
              571
            ],
            [
              476,
              566
            ],
            [
              420,
              500
            ],
            [
              435,
              489
            ],
            [
              555,
              408
            ],
            [
              555,
              409
            ],
            [
              568,
              422
            ],
            [
              568,
              423
            ],
            [
              581,
              436
            ],
            [
              581,
              437
            ],
            [
              594,
              450
            ],
            [
              622,
              480
            ],
            [
              622,
              481
            ],
            [
              647,
              507
            ],
            [
              649,
              510
            ],
            [
              665,
              527
            ],
            [
              665,
              528
            ]
          ]
        ]
      },
      {
        "centroid": [
          563.1764705882352,
          516.1176470588235
        ],
        "area": [
          [
            [
              669,
              515
            ],
            [
              651,
              524
            ],
            [
              643,
              527
            ],
            [
              628,
              535
            ],
            [
              627,
              535
            ],
            [
              627,
              534
            ],
            [
              617,
              533
            ],
            [
              525,
              579
            ],
            [
              521,
              580
            ],
            [
              493,
              531
            ],
            [
              485,
              528
            ],
            [
              481,
              529
            ],
            [
              479,
              526
            ],
            [
              437,
              452
            ],
            [
              437,
              450
            ],
            [
              585,
              381
            ],
            [
              669,
              515
            ]
          ]
        ]
      },
      {
        "centroid": [
          592.4090909090909,
          480.54545454545456
        ],
        "area": [
          [
            [
              674,
              503
            ],
            [
              658,
              508
            ],
            [
              645,
              511
            ],
            [
              641,
              513
            ],
            [
              631,
              516
            ],
            [
              630,
              516
            ],
            [
              629,
              514
            ],
            [
              621,
              513
            ],
            [
              615,
              514
            ],
            [
              614,
              515
            ],
            [
              609,
              516
            ],
            [
              605,
              518
            ],
            [
              518,
              544
            ],
            [
              500,
              490
            ],
            [
              490,
              488
            ],
            [
              489,
              487
            ],
            [
              463,
              407
            ],
            [
              463,
              404
            ],
            [
              619,
              359
            ],
            [
              620,
              360
            ],
            [
              625,
              373
            ],
            [
              674,
              503
            ]
          ]
        ]
      },
      {
        "centroid": [
          594.1034482758621,
          459.82758620689657
        ],
        "area": [
          [
            [
              682,
              491
            ],
            [
              680,
              492
            ],
            [
              666,
              494
            ],
            [
              655,
              494
            ],
            [
              654,
              495
            ],
            [
              637,
              498
            ],
            [
              636,
              498
            ],
            [
              635,
              494
            ],
            [
              625,
              493
            ],
            [
              624,
              494
            ],
            [
              617,
              494
            ],
            [
              616,
              495
            ],
            [
              611,
              495
            ],
            [
              610,
              496
            ],
            [
              597,
              497
            ],
            [
              595,
              498
            ],
            [
              522,
              508
            ],
            [
              517,
              466
            ],
            [
              517,
              455
            ],
            [
              516,
              455
            ],
            [
              515,
              448
            ],
            [
              506,
              449
            ],
            [
              497,
              371
            ],
            [
              497,
              364
            ],
            [
              499,
              363
            ],
            [
              506,
              362
            ],
            [
              657,
              342
            ],
            [
              658,
              343
            ],
            [
              682,
              491
            ]
          ]
        ]
      },
      {
        "centroid": [
          629.5714285714286,
          411.62857142857143
        ],
        "area": [
          [
            [
              698,
              347
            ],
            [
              697,
              349
            ],
            [
              697,
              372
            ],
            [
              696,
              380
            ],
            [
              696,
              395
            ],
            [
              695,
              401
            ],
            [
              695,
              417
            ],
            [
              694,
              419
            ],
            [
              694,
              442
            ],
            [
              693,
              445
            ],
            [
              693,
              461
            ],
            [
              692,
              461
            ],
            [
              692,
              482
            ],
            [
              681,
              481
            ],
            [
              646,
              481
            ],
            [
              639,
              475
            ],
            [
              619,
              475
            ],
            [
              613,
              474
            ],
            [
              569,
              474
            ],
            [
              561,
              473
            ],
            [
              534,
              473
            ],
            [
              534,
              466
            ],
            [
              536,
              451
            ],
            [
              538,
              421
            ],
            [
              537,
              413
            ],
            [
              530,
              413
            ],
            [
              530,
              407
            ],
            [
              537,
              332
            ],
            [
              538,
              329
            ],
            [
              584,
              329
            ],
            [
              585,
              330
            ],
            [
              647,
              330
            ],
            [
              649,
              331
            ],
            [
              698,
              331
            ],
            [
              698,
              347
            ]
          ]
        ]
      },
      {
        "centroid": [
          650.7352941176471,
          390.1764705882353
        ],
        "area": [
          [
            [
              740,
              330
            ],
            [
              737,
              339
            ],
            [
              736,
              346
            ],
            [
              735,
              347
            ],
            [
              734,
              351
            ],
            [
              733,
              359
            ],
            [
              731,
              363
            ],
            [
              730,
              370
            ],
            [
              709,
              454
            ],
            [
              707,
              459
            ],
            [
              704,
              474
            ],
            [
              675,
              468
            ],
            [
              659,
              466
            ],
            [
              653,
              459
            ],
            [
              651,
              458
            ],
            [
              641,
              457
            ],
            [
              639,
              456
            ],
            [
              618,
              453
            ],
            [
              552,
              441
            ],
            [
              553,
              435
            ],
            [
              555,
              431
            ],
            [
              559,
              415
            ],
            [
              563,
              405
            ],
            [
              567,
              389
            ],
            [
              565,
              383
            ],
            [
              560,
              382
            ],
            [
              560,
              381
            ],
            [
              582,
              308
            ],
            [
              584,
              302
            ],
            [
              585,
              301
            ],
            [
              589,
              301
            ],
            [
              739,
              326
            ],
            [
              740,
              327
            ],
            [
              740,
              330
            ]
          ]
        ]
      },
      {
        "centroid": [
          668.6666666666666,
          397.09090909090907
        ],
        "area": [
          [
            [
              782,
              329
            ],
            [
              756,
              383
            ],
            [
              749,
              401
            ],
            [
              737,
              424
            ],
            [
              736,
              428
            ],
            [
              730,
              441
            ],
            [
              717,
              467
            ],
            [
              715,
              467
            ],
            [
              703,
              463
            ],
            [
              694,
              459
            ],
            [
              689,
              458
            ],
            [
              675,
              453
            ],
            [
              671,
              445
            ],
            [
              669,
              445
            ],
            [
              665,
              443
            ],
            [
              640,
              435
            ],
            [
              633,
              432
            ],
            [
              577,
              413
            ],
            [
              576,
              412
            ],
            [
              579,
              405
            ],
            [
              583,
              399
            ],
            [
              598,
              369
            ],
            [
              599,
              369
            ],
            [
              600,
              366
            ],
            [
              600,
              358
            ],
            [
              599,
              357
            ],
            [
              597,
              357
            ],
            [
              596,
              355
            ],
            [
              621,
              307
            ],
            [
              636,
              280
            ],
            [
              780,
              327
            ],
            [
              782,
              328
            ],
            [
              782,
              329
            ]
          ]
        ]
      },
      {
        "centroid": [
          698.4642857142857,
          397.0357142857143
        ],
        "area": [
          [
            [
              822,
              335
            ],
            [
              783,
              387
            ],
            [
              780,
              398
            ],
            [
              766,
              417
            ],
            [
              761,
              422
            ],
            [
              760,
              425
            ],
            [
              737,
              455
            ],
            [
              732,
              463
            ],
            [
              730,
              463
            ],
            [
              724,
              460
            ],
            [
              710,
              451
            ],
            [
              706,
              450
            ],
            [
              693,
              443
            ],
            [
              689,
              434
            ],
            [
              683,
              431
            ],
            [
              681,
              429
            ],
            [
              659,
              418
            ],
            [
              605,
              388
            ],
            [
              605,
              387
            ],
            [
              608,
              383
            ],
            [
              632,
              353
            ],
            [
              634,
              351
            ],
            [
              635,
              351
            ],
            [
              637,
              347
            ],
            [
              638,
              338
            ],
            [
              635,
              336
            ],
            [
              690,
              267
            ],
            [
              822,
              335
            ]
          ]
        ]
      },
      {
        "centroid": [
          720.6530612244898,
          357.6734693877551
        ],
        "area": [
          [
            [
              860,
              348
            ],
            [
              839,
              367
            ],
            [
              810,
              395
            ],
            [
              808,
              396
            ],
            [
              807,
              405
            ],
            [
              772,
              438
            ],
            [
              757,
              451
            ],
            [
              752,
              457
            ],
            [
              746,
              462
            ],
            [
              714,
              437
            ],
            [
              713,
              436
            ],
            [
              711,
              427
            ],
            [
              707,
              423
            ],
            [
              706,
              423
            ],
            [
              703,
              420
            ],
            [
              693,
              413
            ],
            [
              683,
              404
            ],
            [
              682,
              404
            ],
            [
              679,
              401
            ],
            [
              678,
              401
            ],
            [
              638,
              369
            ],
            [
              639,
              367
            ],
            [
              641,
              366
            ],
            [
              648,
              359
            ],
            [
              649,
              359
            ],
            [
              656,
              352
            ],
            [
              657,
              352
            ],
            [
              665,
              344
            ],
            [
              667,
              343
            ],
            [
              679,
              332
            ],
            [
              678,
              322
            ],
            [
              680,
              320
            ],
            [
              691,
              311
            ],
            [
              697,
              305
            ],
            [
              699,
              304
            ],
            [
              705,
              298
            ],
            [
              707,
              297
            ],
            [
              713,
              291
            ],
            [
              715,
              290
            ],
            [
              721,
              284
            ],
            [
              723,
              283
            ],
            [
              729,
              277
            ],
            [
              731,
              276
            ],
            [
              737,
              270
            ],
            [
              739,
              269
            ],
            [
              745,
              263
            ],
            [
              826,
              322
            ],
            [
              857,
              345
            ],
            [
              860,
              348
            ]
          ]
        ]
      },
      {
        "centroid": [
          757.5483870967741,
          379.5806451612903
        ],
        "area": [
          [
            [
              896,
              366
            ],
            [
              833,
              407
            ],
            [
              831,
              409
            ],
            [
              831,
              417
            ],
            [
              814,
              428
            ],
            [
              806,
              432
            ],
            [
              802,
              436
            ],
            [
              781,
              450
            ],
            [
              771,
              455
            ],
            [
              768,
              458
            ],
            [
              762,
              462
            ],
            [
              760,
              462
            ],
            [
              736,
              435
            ],
            [
              734,
              432
            ],
            [
              734,
              423
            ],
            [
              690,
              374
            ],
            [
              675,
              357
            ],
            [
              674,
              355
            ],
            [
              677,
              352
            ],
            [
              700,
              338
            ],
            [
              703,
              337
            ],
            [
              705,
              335
            ],
            [
              711,
              332
            ],
            [
              716,
              328
            ],
            [
              719,
              327
            ],
            [
              721,
              325
            ],
            [
              723,
              315
            ],
            [
              724,
              314
            ],
            [
              790,
              273
            ],
            [
              801,
              267
            ],
            [
              896,
              366
            ]
          ]
        ]
      },
      {
        "centroid": [
          804.2307692307693,
          391.5
        ],
        "area": [
          [
            [
              928,
              389
            ],
            [
              916,
              395
            ],
            [
              857,
              420
            ],
            [
              856,
              421
            ],
            [
              852,
              432
            ],
            [
              848,
              434
            ],
            [
              825,
              443
            ],
            [
              816,
              448
            ],
            [
              787,
              460
            ],
            [
              782,
              463
            ],
            [
              777,
              465
            ],
            [
              775,
              465
            ],
            [
              755,
              432
            ],
            [
              757,
              423
            ],
            [
              755,
              419
            ],
            [
              753,
              417
            ],
            [
              713,
              350
            ],
            [
              712,
              346
            ],
            [
              755,
              328
            ],
            [
              757,
              328
            ],
            [
              765,
              324
            ],
            [
              767,
              315
            ],
            [
              769,
              314
            ],
            [
              850,
              280
            ],
            [
              855,
              279
            ],
            [
              928,
              389
            ]
          ]
        ]
      },
      {
        "centroid": [
          829.5151515151515,
          405.5151515151515
        ],
        "area": [
          [
            [
              943,
              392
            ],
            [
              937,
              422
            ],
            [
              874,
              439
            ],
            [
              872,
              440
            ],
            [
              871,
              449
            ],
            [
              869,
              450
            ],
            [
              854,
              454
            ],
            [
              843,
              456
            ],
            [
              842,
              457
            ],
            [
              815,
              464
            ],
            [
              800,
              467
            ],
            [
              799,
              468
            ],
            [
              792,
              470
            ],
            [
              789,
              470
            ],
            [
              786,
              464
            ],
            [
              776,
              436
            ],
            [
              776,
              433
            ],
            [
              777,
              433
            ],
            [
              779,
              425
            ],
            [
              778,
              421
            ],
            [
              777,
              420
            ],
            [
              753,
              354
            ],
            [
              750,
              344
            ],
            [
              798,
              332
            ],
            [
              801,
              332
            ],
            [
              802,
              331
            ],
            [
              805,
              331
            ],
            [
              807,
              330
            ],
            [
              811,
              321
            ],
            [
              905,
              298
            ],
            [
              907,
              301
            ],
            [
              943,
              386
            ],
            [
              943,
              392
            ]
          ]
        ]
      },
      {
        "centroid": [
          858.3793103448276,
          410.67241379310343
        ],
        "area": [
          [
            [
              962,
              374
            ],
            [
              956,
              381
            ],
            [
              953,
              390
            ],
            [
              945,
              389
            ],
            [
              939,
              390
            ],
            [
              937,
              395
            ],
            [
              936,
              401
            ],
            [
              922,
              451
            ],
            [
              921,
              454
            ],
            [
              919,
              456
            ],
            [
              907,
              456
            ],
            [
              898,
              458
            ],
            [
              887,
              458
            ],
            [
              886,
              468
            ],
            [
              885,
              469
            ],
            [
              881,
              470
            ],
            [
              871,
              470
            ],
            [
              871,
              471
            ],
            [
              859,
              471
            ],
            [
              850,
              473
            ],
            [
              837,
              473
            ],
            [
              837,
              474
            ],
            [
              830,
              475
            ],
            [
              817,
              475
            ],
            [
              817,
              476
            ],
            [
              807,
              476
            ],
            [
              807,
              477
            ],
            [
              801,
              477
            ],
            [
              795,
              439
            ],
            [
              799,
              439
            ],
            [
              801,
              431
            ],
            [
              800,
              429
            ],
            [
              800,
              423
            ],
            [
              799,
              422
            ],
            [
              799,
              417
            ],
            [
              797,
              409
            ],
            [
              797,
              403
            ],
            [
              796,
              402
            ],
            [
              796,
              397
            ],
            [
              795,
              396
            ],
            [
              795,
              389
            ],
            [
              794,
              388
            ],
            [
              788,
              348
            ],
            [
              790,
              347
            ],
            [
              807,
              346
            ],
            [
              821,
              344
            ],
            [
              829,
              344
            ],
            [
              832,
              343
            ],
            [
              841,
              343
            ],
            [
              843,
              342
            ],
            [
              849,
              342
            ],
            [
              853,
              334
            ],
            [
              859,
              333
            ],
            [
              944,
              325
            ],
            [
              952,
              325
            ],
            [
              953,
              327
            ],
            [
              962,
              370
            ],
            [
              962,
              374
            ]
          ]
        ]
      },
      {
        "centroid": [
          920.8653846153846,
          404.15384615384613
        ],
        "area": [
          [
            [
              994,
              426
            ],
            [
              993,
              429
            ],
            [
              989,
              429
            ],
            [
              987,
              424
            ],
            [
              981,
              418
            ],
            [
              974,
              419
            ],
            [
              973,
              424
            ],
            [
              967,
              431
            ],
            [
              962,
              429
            ],
            [
              963,
              412
            ],
            [
              952,
              406
            ],
            [
              947,
              405
            ],
            [
              938,
              405
            ],
            [
              936,
              409
            ],
            [
              933,
              412
            ],
            [
              933,
              415
            ],
            [
              932,
              416
            ],
            [
              923,
              417
            ],
            [
              920,
              423
            ],
            [
              917,
              427
            ],
            [
              906,
              450
            ],
            [
              884,
              490
            ],
            [
              873,
              489
            ],
            [
              856,
              489
            ],
            [
              812,
              486
            ],
            [
              812,
              474
            ],
            [
              814,
              448
            ],
            [
              819,
              448
            ],
            [
              821,
              439
            ],
            [
              821,
              428
            ],
            [
              825,
              356
            ],
            [
              829,
              357
            ],
            [
              865,
              358
            ],
            [
              867,
              359
            ],
            [
              883,
              359
            ],
            [
              892,
              357
            ],
            [
              893,
              352
            ],
            [
              894,
              352
            ],
            [
              895,
              353
            ],
            [
              914,
              353
            ],
            [
              917,
              354
            ],
            [
              933,
              354
            ],
            [
              933,
              355
            ],
            [
              952,
              355
            ],
            [
              955,
              356
            ],
            [
              971,
              356
            ],
            [
              971,
              357
            ],
            [
              990,
              357
            ],
            [
              993,
              358
            ],
            [
              993,
              403
            ],
            [
              994,
              412
            ],
            [
              994,
              426
            ]
          ]
        ]
      },
      {
        "centroid": [
          922.0833333333334,
          441.4583333333333
        ],
        "area": [
          [
            [
              1028,
              397
            ],
            [
              1021,
              432
            ],
            [
              1019,
              437
            ],
            [
              1019,
              443
            ],
            [
              1010,
              488
            ],
            [
              1009,
              491
            ],
            [
              1001,
              495
            ],
            [
              978,
              482
            ],
            [
              977,
              481
            ],
            [
              979,
              477
            ],
            [
              975,
              470
            ],
            [
              972,
              470
            ],
            [
              971,
              466
            ],
            [
              969,
              464
            ],
            [
              962,
              462
            ],
            [
              961,
              456
            ],
            [
              959,
              453
            ],
            [
              958,
              453
            ],
            [
              954,
              450
            ],
            [
              946,
              453
            ],
            [
              944,
              457
            ],
            [
              943,
              457
            ],
            [
              942,
              459
            ],
            [
              938,
              458
            ],
            [
              935,
              456
            ],
            [
              937,
              445
            ],
            [
              937,
              441
            ],
            [
              923,
              432
            ],
            [
              914,
              430
            ],
            [
              909,
              435
            ],
            [
              907,
              436
            ],
            [
              906,
              438
            ],
            [
              903,
              438
            ],
            [
              898,
              445
            ],
            [
              893,
              449
            ],
            [
              889,
              456
            ],
            [
              882,
              464
            ],
            [
              882,
              465
            ],
            [
              877,
              470
            ],
            [
              869,
              481
            ],
            [
              865,
              484
            ],
            [
              863,
              488
            ],
            [
              859,
              492
            ],
            [
              858,
              494
            ],
            [
              856,
              495
            ],
            [
              851,
              503
            ],
            [
              841,
              500
            ],
            [
              822,
              497
            ],
            [
              821,
              496
            ],
            [
              821,
              493
            ],
            [
              830,
              458
            ],
            [
              834,
              459
            ],
            [
              838,
              453
            ],
            [
              856,
              383
            ],
            [
              860,
              370
            ],
            [
              890,
              376
            ],
            [
              891,
              377
            ],
            [
              895,
              377
            ],
            [
              897,
              378
            ],
            [
              900,
              378
            ],
            [
              901,
              379
            ],
            [
              910,
              380
            ],
            [
              911,
              381
            ],
            [
              916,
              381
            ],
            [
              917,
              382
            ],
            [
              927,
              381
            ],
            [
              927,
              378
            ],
            [
              928,
              376
            ],
            [
              929,
              376
            ],
            [
              1024,
              395
            ],
            [
              1028,
              396
            ],
            [
              1028,
              397
            ]
          ]
        ]
      },
      {
        "centroid": [
          919.3870967741935,
          478.03225806451616
        ],
        "area": [
          [
            [
              1041,
              436
            ],
            [
              1012,
              542
            ],
            [
              1005,
              560
            ],
            [
              1003,
              563
            ],
            [
              1002,
              563
            ],
            [
              989,
              551
            ],
            [
              977,
              542
            ],
            [
              977,
              531
            ],
            [
              974,
              531
            ],
            [
              969,
              526
            ],
            [
              964,
              526
            ],
            [
              960,
              527
            ],
            [
              941,
              511
            ],
            [
              942,
              509
            ],
            [
              939,
              500
            ],
            [
              936,
              500
            ],
            [
              932,
              493
            ],
            [
              929,
              492
            ],
            [
              926,
              484
            ],
            [
              926,
              481
            ],
            [
              925,
              481
            ],
            [
              925,
              480
            ],
            [
              921,
              477
            ],
            [
              910,
              480
            ],
            [
              910,
              483
            ],
            [
              909,
              483
            ],
            [
              905,
              480
            ],
            [
              903,
              478
            ],
            [
              904,
              465
            ],
            [
              902,
              463
            ],
            [
              902,
              462
            ],
            [
              901,
              462
            ],
            [
              897,
              458
            ],
            [
              896,
              456
            ],
            [
              893,
              454
            ],
            [
              893,
              453
            ],
            [
              884,
              450
            ],
            [
              881,
              453
            ],
            [
              878,
              454
            ],
            [
              877,
              456
            ],
            [
              875,
              457
            ],
            [
              873,
              460
            ],
            [
              873,
              462
            ],
            [
              872,
              462
            ],
            [
              852,
              480
            ],
            [
              851,
              480
            ],
            [
              846,
              485
            ],
            [
              845,
              485
            ],
            [
              840,
              490
            ],
            [
              839,
              490
            ],
            [
              833,
              496
            ],
            [
              844,
              472
            ],
            [
              848,
              473
            ],
            [
              851,
              472
            ],
            [
              891,
              389
            ],
            [
              904,
              393
            ],
            [
              947,
              409
            ],
            [
              958,
              407
            ],
            [
              958,
              405
            ],
            [
              960,
              405
            ],
            [
              1041,
              434
            ],
            [
              1041,
              436
            ]
          ]
        ]
      },
      {
        "centroid": [
          933.4516129032259,
          517.7096774193549
        ],
        "area": [
          [
            [
              1033,
              464
            ],
            [
              995,
              603
            ],
            [
              989,
              600
            ],
            [
              981,
              594
            ],
            [
              978,
              593
            ],
            [
              972,
              589
            ],
            [
              968,
              588
            ],
            [
              940,
              572
            ],
            [
              931,
              566
            ],
            [
              930,
              566
            ],
            [
              919,
              554
            ],
            [
              917,
              553
            ],
            [
              914,
              553
            ],
            [
              909,
              546
            ],
            [
              898,
              534
            ],
            [
              897,
              524
            ],
            [
              895,
              522
            ],
            [
              891,
              514
            ],
            [
              886,
              515
            ],
            [
              886,
              503
            ],
            [
              881,
              499
            ],
            [
              870,
              499
            ],
            [
              866,
              495
            ],
            [
              866,
              483
            ],
            [
              916,
              415
            ],
            [
              919,
              412
            ],
            [
              922,
              413
            ],
            [
              971,
              440
            ],
            [
              981,
              439
            ],
            [
              983,
              437
            ],
            [
              1033,
              464
            ]
          ]
        ]
      },
      {
        "centroid": [
          922.2758620689655,
          543.7586206896551
        ],
        "area": [
          [
            [
              1017,
              486
            ],
            [
              977,
              639
            ],
            [
              974,
              637
            ],
            [
              966,
              629
            ],
            [
              962,
              627
            ],
            [
              954,
              621
            ],
            [
              928,
              600
            ],
            [
              922,
              594
            ],
            [
              918,
              592
            ],
            [
              901,
              576
            ],
            [
              891,
              576
            ],
            [
              889,
              577
            ],
            [
              883,
              571
            ],
            [
              876,
              567
            ],
            [
              853,
              548
            ],
            [
              845,
              531
            ],
            [
              841,
              531
            ],
            [
              844,
              522
            ],
            [
              853,
              513
            ],
            [
              855,
              512
            ],
            [
              864,
              503
            ],
            [
              873,
              503
            ],
            [
              895,
              483
            ],
            [
              942,
              438
            ],
            [
              990,
              475
            ],
            [
              999,
              475
            ],
            [
              1001,
              473
            ],
            [
              1016,
              484
            ],
            [
              1017,
              486
            ]
          ]
        ]
      },
      {
        "centroid": [
          901.3333333333334,
          558.3
        ],
        "area": [
          [
            [
              992,
              503
            ],
            [
              954,
              662
            ],
            [
              952,
              670
            ],
            [
              951,
              671
            ],
            [
              923,
              640
            ],
            [
              911,
              625
            ],
            [
              901,
              615
            ],
            [
              887,
              599
            ],
            [
              881,
              594
            ],
            [
              880,
              595
            ],
            [
              878,
              595
            ],
            [
              865,
              582
            ],
            [
              853,
              568
            ],
            [
              845,
              557
            ],
            [
              843,
              556
            ],
            [
              836,
              549
            ],
            [
              833,
              545
            ],
            [
              833,
              544
            ],
            [
              868,
              521
            ],
            [
              869,
              521
            ],
            [
              872,
              524
            ],
            [
              879,
              521
            ],
            [
              881,
              521
            ],
            [
              881,
              520
            ],
            [
              888,
              515
            ],
            [
              956,
              470
            ],
            [
              960,
              468
            ],
            [
              984,
              493
            ],
            [
              992,
              502
            ],
            [
              992,
              503
            ]
          ]
        ]
      }
    ]
  }
]

